import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-sequences-of-a-subject',
  templateUrl: './list-of-sequences-of-a-subject.component.html',
  styleUrls: ['./list-of-sequences-of-a-subject.component.css']
})
export class ListOfSequencesOfASubjectComponent implements OnInit, DoCheck  {

  public id = '';
  public id2 = '';
  public seccionSeleccionada9 = '';
  public seccionSeleccionada8 = '';
  public materiaSeleccionada7 = '';
  public materiaSeleccionada = '';
  public materiaSeleccionada2 = '';
  public nombreMateriaSeleccionada = '';
  public bloqueSeleccionado = '';
  public bloqueSeleccionado2 = '';
  public bloqueSeleccionado3 = '';
  public nombreBloqueSeleccionado = '';
  public titleSequence = '';
  public titleSequence2 = '';
  public titleSequence3 = '';
  public idInteractivoSequence = '';
  public idInteractivoSequence2 = '';
  public idInteractivoSequence3 = '';
  private position = 0;
  public subjects = [];
  public subjects2 = [];
  private blocks = [];
  public blocks2 = [];
  public blocks3 = [];
  public sections = [];
  private sequences = [];
  public sequences2 = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);

    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_secuencias;
      this.permisoEditar = info.permisos[0].actualizar_secuencias;
      this.permisoEliminar = info.permisos[0].eliminar_secuencias;
      if(info.permisos[0].visualizar_secuencias === true){
        let listado;
        this.api.obtenerListadoMaterias(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).materias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Materias");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Materias");
          }
        }, () => {
          this.subjects = listado;
        });
        let listado2;
        this.api.obtenerListadoBloques(this.token).subscribe(respuesta => {
          listado2 = JSON.parse(JSON.stringify(respuesta)).bloques;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Bloques");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Bloques");
          }
        }, () => {
          this.blocks = listado2;
          this.blocks3 = listado2;
        });
        let listado3;
        this.api.obtenerListadoSecuencias(this.token).subscribe(respuesta => {
          listado3 = JSON.parse(JSON.stringify(respuesta)).secuencias;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Bloques");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Bloques");
          }
        }, () => {
          // console.log("SECUENCIAS: ", listado3);
          this.sequences = listado3;
          this.sequences2 = listado3;
          this.sequences = this.sortSequencesByTitle(this.sequences);
          this.sequences2 = this.sortSequencesByTitle(this.sequences2);
          // this.sequences.sort(function(a, b){
          //   if(a.titulo < b.titulo) { return -1; }
          // });
          // this.sequences2.sort(function(a, b){
          //   if(a.titulo < b.titulo) { return -1; }
          // });
          this.notify.showInfo("Información actualizada", "Secuencias");
        });
        let listado4;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado4 = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.sections = listado4;
        });
      } else if(info.permisos[0].agregar_secuencias !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  ngDoCheck(){
    for(let k = 0; k < this.sequences.length; k++){
      for(let i = 0; i < this.subjects.length; i++){
        if(this.subjects[i].id === this.sequences[k].materia){
          this.sequences[k].nombreMateria = this.subjects[i].titulo;
        }
      }
      for(let m = 0; m < this.blocks.length; m++){
        if(this.blocks[m].id === this.sequences[k].bloque){
          this.sequences[k].nombreBloque = this.blocks[m].titulo;
        }
      }
    }
    this.sequences.sort(function(a, b){
      const l1 = a.titulo.split(' ')[0];
      const l2 = b.titulo.split(' ')[0];
      const n1 = l1.slice(0, l1.length - 1);
      const n2 = l2.slice(0, l2.length - 1);
      if(Number(n1) < Number(n2)) { 
        return -1;
      } else if(Number(n1) < Number(n2) && a.nombreBloque > b.nombreBloque) { 
        return 1;
      }
    }); 
    this.sequences2.sort(function(a, b){
      // if(a.titulo < b.titulo) { 
      //   return -1;
      // } else if(a.titulo < b.titulo && a.nombreBloque > b.nombreBloque) { 
      //   return 1;
      // }
      const l1 = a.titulo.split(' ')[0];
      const l2 = b.titulo.split(' ')[0];
      const n1 = l1.slice(0, l1.length - 1);
      const n2 = l2.slice(0, l2.length - 1);
      if(Number(n1) < Number(n2)) { 
        return -1;
      } else if(Number(n1) < Number(n2) && a.nombreBloque > b.nombreBloque) { 
        return 1;
      }
    }); 
  }

  open(content) {
    this.titleSequence = '';
    this.idInteractivoSequence = '';
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  seleccionarMateria(){
    if(this.materiaSeleccionada !== ''){
      this.blocks2 = this.filtrarBloquesPorMateria(this.materiaSeleccionada);
    } else {
      this.blocks2 = [];
    }
  }

  listenNameOfSequence(name){
    name.target.value = name.target.value.toUpperCase();
    this.titleSequence = name.target.value;
    this.titleSequence2 = name.target.value;
  }

  listenInteraciveOfSequence(name){
    name.target.value = name.target.value.toUpperCase();
    this.idInteractivoSequence = name.target.value;
    this.idInteractivoSequence2 = name.target.value;
  }
  
  listenIdOfSequence(id){
    this.id2 = id.target.value;
  }

  // agregarUnaSecuencia(){
  //   this.materiaSeleccionada = '';
  //   this.bloqueSeleccionado = '';
  // }

  seleccionarNivel(){
    this.materiaSeleccionada7 = '';
    this.bloqueSeleccionado3 = '';
    this.blocks3 = [];
    if(this.seccionSeleccionada8 !== ''){
      // this.listOfPsl2 = this.filtrarPslPorMateria(this.materiaSeleccionada5);
      this.subjects2 = this.filtrarMateriasPorNivel(this.seccionSeleccionada8);
      this.subjects2.sort(function(a, b){
        if(a.titulo < b.titulo) { return -1; }
      });
      this.sequences2 = [];
      this.notify.showWarning('Selecciona una materia para visualizar sus secuencias', 'Secuencias');
    } else {
      this.subjects2 = [];
      this.sequences2 = this.sequences;
    }
  }

  sortSequencesByTitle(sequences){
    return sequences.sort(function(a, b){
      const l1 = a.titulo.split(' ')[0];
      const l2 = b.titulo.split(' ')[0];
      const n1 = l1.slice(0, l1.length - 1);
      const n2 = l2.slice(0, l2.length - 1);
      return Number(n1) - Number(n2);
    });
  }

  seleccionarNivel2(){
    this.materiaSeleccionada = '';
    this.bloqueSeleccionado = '';
    if(this.seccionSeleccionada9 !== ''){
      this.subjects2 = this.filtrarMateriasPorNivel(this.seccionSeleccionada9);
      this.subjects2.sort(function(a, b){
        if(a.titulo < b.titulo) { return -1; }
      });
      this.blocks2 = [];
    } else {
      this.subjects2 = [];
      this.blocks2 = [];
    }
  }

  seleccionarMateria2(){
    this.bloqueSeleccionado3 = '';
    if(this.materiaSeleccionada7 !== ''){
      this.blocks3 = this.filtrarBloquesPorMateria(this.materiaSeleccionada7);
      this.sequences2 = this.filtrarSecuenciasPorMateria(this.materiaSeleccionada7);
    } else {
      this.blocks3 = [];
      this.sequences2 = [];
      this.notify.showWarning('Selecciona una materia para visualizar sus secuencias', 'Secuencias');
    }
  }

  seleccionarBloque(){
    if(this.bloqueSeleccionado3 !== ''){
      this.sequences2 = this.filtrarSecuenciasPorMateriayBloque(this.materiaSeleccionada7, this.bloqueSeleccionado3);
    } else {
      this.sequences2 = this.sequences2 = this.filtrarSecuenciasPorMateria(this.materiaSeleccionada7);
    }
  }

  filtrarMateriasPorNivel(key: string ) {
    return this.subjects.filter(object => {
      return (object.seccion === key);
    });
  }

  filtrarSecuenciasPorMateria(key: string ) {
    return this.sequences.filter(object => {
      return (object.materia === key);
    });
  }

  filtrarSecuenciasPorMateriayBloque(key: string, key2: string) {
    return this.sequences.filter(object => {
      return object.materia === key && object.bloque === key2;
    });
  }

  mostrarSequence(idSequence, nombreSequence, idMateria, idBloque, posicionSecuencia, idInteractivo){
    this.id = idSequence;
    // console.log("idSequence: ", idSequence);
    // console.log("posicionSecuencia: ", posicionSecuencia);
    this.titleSequence2 = nombreSequence;
    this.titleSequence3 = nombreSequence;
    this.idInteractivoSequence2 = idInteractivo;
    this.idInteractivoSequence3 = idInteractivo;
    this.materiaSeleccionada2 = idMateria;
    this.bloqueSeleccionado2 = idBloque;
    this.position = posicionSecuencia;
    this.nombreMateriaSeleccionada = this.filtrarMaterias(this.materiaSeleccionada2)[0].titulo;
    this.nombreBloqueSeleccionado = this.filtrarBloquesPorMateria(this.materiaSeleccionada2)[0].titulo;
    if(this.materiaSeleccionada2 !== ''){
      this.blocks2 = this.filtrarBloquesPorMateria(this.materiaSeleccionada2);
    } else {
      this.blocks2 = [];
    }
  }

  agregarSecuencia(){
    const infoMateria = this.filtrarMaterias(this.materiaSeleccionada)[0];
    let idRespuesta;
    this.api.agregarUnaSecuencia(this.token, infoMateria.seccion, infoMateria.grado, this.materiaSeleccionada, this.bloqueSeleccionado, this.titleSequence, this.idInteractivoSequence).subscribe(respuesta => {
      idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError('Error 400', 'Secuencias');
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 403) {
        this.notify.showWarning('El nombre ya existe en otra secuencia de la misma sesión. Prueba con otro diferente', 'Secuencias');
      }
      if (err.status === 500) {
        this.notify.showError('Error 500', 'Secuencias');
      }
    }, () => {
      this.sequences.push({
        id: idRespuesta,
        titulo: this.titleSequence,
        interactivo: this.idInteractivoSequence,
        materia: this.materiaSeleccionada,
        nombreMateria: this.filtrarMaterias(this.materiaSeleccionada)[0].titulo,
        bloque: this.bloqueSeleccionado,
        nombreBloque: this.filtrarBloquesPorId(this.bloqueSeleccionado)[0].titulo,
        utilizado: 0
      });
      this.sequences2.push({
        id: idRespuesta,
        titulo: this.titleSequence,
        interactivo: this.idInteractivoSequence,
        materia: this.materiaSeleccionada,
        nombreMateria: this.filtrarMaterias(this.materiaSeleccionada)[0].titulo,
        bloque: this.bloqueSeleccionado,
        nombreBloque: this.filtrarBloquesPorId(this.bloqueSeleccionado)[0].titulo,
        utilizado: 0
      });
      this.materiaSeleccionada = '';
      this.bloqueSeleccionado = '';
      this.titleSequence = '';
      this.idInteractivoSequence = '';
      this.notify.showInfo('Se agregó la secuencia correctamente', 'Secuencias');
    });
  }

  actualizarSecuencia(){
    if(this.titleSequence2.trim() !== this.titleSequence3.trim() || this.idInteractivoSequence2.trim() !== this.idInteractivoSequence3.trim()){
      this.peticionActualizar();
    } else {
      if(this.titleSequence2.trim() === this.titleSequence3.trim()){
        this.notify.showWarning('Los nombres no pueden ser los mismos', 'Secuencias');
      } else {
        if(this.idInteractivoSequence2.trim() === this.idInteractivoSequence3.trim()){
          this.notify.showWarning('Los interactivos no pueden ser los mismos', 'Interactivos');
        }
      }
    }
  }

  peticionActualizar(){
    let estadoRespuesta;
    this.api.actualizarUnaSecuencia(this.token, this.id, this.titleSequence2.trim(), this.idInteractivoSequence2.trim()).subscribe(respuesta => {
      estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError('Error 400', 'Secuencias');
      }
      if (err.status === 401) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 404) {
        this.notify.showWarning('La secuencia que intentas actualizar no existe', 'Secuencias');
      }
      if (err.status === 403) {
        this.notify.showWarning('El nombre ya existe en otra secuencia de la misma sesión. Prueba con otro diferente', 'Secuencias');
      }
      if (err.status === 500) {
        this.notify.showError('Error 500', 'Secuencias');
      }
    }, () => {
      if(estadoRespuesta === 200){
        this.notify.showInfo('Se actualizó la secuencia correctamente', 'Secuencias');
        //this.sequences[this.position].titulo = this.titleSequence2.trim();
        for(let i = 0; i < this.sequences.length; i++){
          if(this.sequences[i].id === this.id){
            this.sequences[i].titulo = this.titleSequence2.trim();
            this.sequences[i].interactivo = this.idInteractivoSequence2.trim();
            this.sequences = this.sortSequencesByTitle(this.sequences);
            // this.sequences.sort(function(a, b){
            //   if(a.titulo < b.titulo) { return -1; }
            // });
          }
        }
        for(let i = 0; i < this.sequences2.length; i++){
          if(this.sequences2[i].id === this.id){
            this.sequences2[i].titulo = this.titleSequence2.trim();
            this.sequences[i].interactivo = this.idInteractivoSequence2.trim();
            this.sequences2 = this.sortSequencesByTitle(this.sequences2);
            // this.sequences2.sort(function(a, b){
            //   if(a.titulo < b.titulo) { return -1; }
            // });
          }
        }
      } else {
        this.notify.showError('Ocurrió un error al procesar la solicitud', 'Secuencias');
      }
    });
  }

  eliminarSecuencia(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnaSecuencia(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Secuencias');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('La secuencia que intentas eliminar no existe', 'Secuencias');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Secuencias');
        }
      }, () => {
        if(estadoRespuesta === 200){
          //this.sequences.splice(this.position, 1);
          for(let i = 0; i < this.sequences.length; i++){
            if(this.sequences[i].id === this.id){
              this.sequences.splice(i, 1);
            }
          }
          for(let i = 0; i < this.sequences2.length; i++){
            if(this.sequences2[i].id === this.id){
              this.sequences2.splice(i, 1);
            }
          }
          this.notify.showInfo('Se eliminó correctamente la secuencia', 'Secuencias');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Secuencias');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'Secuencias');
    }
  }

  filtrarBloquesPorMateria(key: string ) {
    return this.blocks.filter(object => {
      return object.materia === key;
    });
  }

  filtrarBloquesPorId(key: string ) {
    return this.blocks.filter(object => {
      return object.id === key;
    });
  }

  filtrarMaterias(key: string ) {
    return this.subjects.filter(object => {
      return object.id === key;
    });
  }

}
