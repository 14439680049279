import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-edit-a-topic-page',
  templateUrl: './edit-a-topic-page.component.html',
  styleUrls: ['./edit-a-topic-page.component.css']
})
export class EditATopicPageComponent implements OnInit {

  editorForm: FormGroup;
  editorForm2: FormGroup;
  public options = false;
  public general = true;
  public activities = true;
  public quizzes = true;
  public page = true
  public hasActivity = false;
  public hasQuiz = false;
  public textInPage = '';
  public quizSelected = '';
  public listOfActivities = [{
    title: ''
  }];
  blured = false
  focused = false

  public sections = [];
  public grades = [];
  public grades2 = [];
  public subjects = [];
  public subjects2 = [];
  public blocks = [];
  public blocks2 = [];
  public sequences = [];
  public sequences2 = [];
  public pages = [];
  public pages2 = [];
  public pages3 = [];
  public ambits = [];
  private ListadoPsl = [];
  public ListadoPsl2 = [];
  public axes = [];
  private topics = [];
  public topics2 = [];
  public isAmbit = undefined;
  public seccionSeleccionadaInPage = '';
  public gradoSeleccionadoInPage = '';
  public materiaSeleccionadaInPage = '';
  public bloqueSeleccionadaInPage = '';
  public secuenciaSeleccionadaInPage = '';
  public paginaSeleccionada = '';
  public ambitoSeleccionado = '';
  public pslSeleccionada = '';
  public ejeSeleccionado = '';
  public temaSeleccionado = '';
  private token = '';
  public infoPage;
  private infoPage3 = [];
  private imagenesSeleccionadas = [];
  private imagenesSeleccionadas2 = [];
  private videosSeleccionados = [];
  private audiosSeleccionados = [];
  private galeriasSeleccionadas = [];
  private wikisSeleccionados = [];
  private contenidoPagina;
  private contenidoPagina2;
  public aeDeUnTema = '';
  public aeDeUnPsl = '';
  public editandoPagina = true;

  constructor(private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.editorForm = new FormGroup({
      'editor': new FormControl('')
    });
    this.editorForm2 = new FormGroup({
      'editor': new FormControl('')
    });
  }

  ngOnInit() {
    if (localStorage.getItem('detallePagina') !== null) {
      this.infoPage = JSON.parse(localStorage.getItem('detallePagina'));
      // localStorage.removeItem('detallePagina');
      // console.log("this.infoPage: ", this.infoPage);
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('secciones') !== null) {
      this.sections = JSON.parse(sessionStorage.getItem('secciones'));
      this.seccionSeleccionadaInPage = this.infoPage.seccion;
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('grados') !== null) {
      this.grades = JSON.parse(sessionStorage.getItem('grados'));
      this.gradoSeleccionadoInPage = this.infoPage.grado;
      this.seleccionarNivelAcademico();
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('materias') !== null) {
      this.subjects = JSON.parse(sessionStorage.getItem('materias'));
      this.materiaSeleccionadaInPage = this.infoPage.materia;
      this.seleccionarGrado();
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('bloques') !== null) {
      this.blocks = JSON.parse(sessionStorage.getItem('bloques'));
      this.bloqueSeleccionadaInPage = this.infoPage.bloque;
      this.seleccionarMateria();
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('secuencias') !== null) {
      this.sequences = JSON.parse(sessionStorage.getItem('secuencias'));
      this.secuenciaSeleccionadaInPage = this.infoPage.secuencia;
      this.seleccionarBloque();
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('paginas') !== null) {
      this.pages = JSON.parse(sessionStorage.getItem('paginas'));
      this.paginaSeleccionada = this.infoPage.id;
      // console.log("this.infoPage: ", this.infoPage);
      this.seleccionarSecuencia();
      if (this.pages2.length > 1) {
        if (this.infoPage.pagina > 0) {
          const paginasLocal = this.pages2;
          for (let k = 0; k < this.pages2.length; k++) {
            if (this.pages2[k].id === this.paginaSeleccionada) {
              this.paginaSeleccionada = this.pages2[this.infoPage.pagina - 1].id;
            }
            if ((k + 1) === this.pages2.length) {
              this.pages3 = paginasLocal;
              this.pages3.splice(this.pages3.length - 1, 1);
            }
          }
        } else {
          this.paginaSeleccionada = "";
          const paginasLocal = this.pages2;
          for (let k = 0; k < this.pages2.length; k++) {
            if ((k + 1) === this.pages2.length) {
              this.pages3 = paginasLocal;
              this.pages3.splice(this.pages3.length - 1, 1);
            }
          }
        }
      } else {
        this.pages3 = [];
      }
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }

    this.hasActivity = this.infoPage.tiene_actividades;
    this.hasQuiz = this.infoPage.tiene_quiz;
    this.imagenesSeleccionadas = this.infoPage.imagenes;
    this.imagenesSeleccionadas2 = this.infoPage.imagenes2;
    this.videosSeleccionados = this.infoPage.videos;
    this.audiosSeleccionados = this.infoPage.audios;
    // console.log("this.infoPage.audios: ", this.infoPage.audios);
    this.galeriasSeleccionadas = this.infoPage.galerias;
    this.wikisSeleccionados = this.infoPage.wikis;
    if (this.hasActivity) {
      this.listOfActivities = this.infoPage.actividades;
    }
    if (this.hasQuiz) {
      this.quizSelected = this.infoPage.quiz;
    }
    if (this.infoPage.contenido !== '') {
      this.editorForm.get('editor').setValue(this.infoPage.contenido);
    }
    if (this.infoPage.contenido2 !== '') {
      this.editorForm2.get('editor').setValue(this.infoPage.contenido2);
    }

    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if (tokenEspirado) {
      this.router.navigate(['', 'auth']);
    } else {
      if (info.permisos[0].actualizar_paginas === true) {
        let listado7;
        this.api.obtenerListadoAmbitos(this.token).subscribe(respuesta => {
          listado7 = JSON.parse(JSON.stringify(respuesta)).ambitos;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Ambitos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Ambitos");
          }
        }, () => {
          this.ambits = listado7;
        });
        let listado8;
        this.api.obtenerListadoPSL(this.token).subscribe(respuesta => {
          listado8 = JSON.parse(JSON.stringify(respuesta)).psl;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "PSL");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "PSL");
          }
        }, () => {
          this.ListadoPsl = listado8;
          if (this.filtrarPslPorId(this.infoPage.psl)[0] !== undefined) {
            this.aeDeUnPsl = this.filtrarPslPorId(this.infoPage.psl)[0].titulo;
          }
          this.ListadoPsl2 = this.filtrarPslPorMateria(this.materiaSeleccionadaInPage);
          this.ListadoPsl2.sort(function (a, b) {
            if (a.aprendizaje < b.aprendizaje) { return -1; }
          });
          // console.log("LISTADOS PSL: ", this.ListadoPsl2);
        });
        let listado9;
        this.api.obtenerListadoEjes(this.token).subscribe(respuesta => {
          listado9 = JSON.parse(JSON.stringify(respuesta)).ejes;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Ejes");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Ejes");
          }
        }, () => {
          this.axes = listado9;
        });
        let listado10;
        this.api.obtenerListadoTemas(this.token).subscribe(respuesta => {
          listado10 = JSON.parse(JSON.stringify(respuesta)).temas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Temas");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Temas");
          }
        }, () => {
          this.topics = listado10;
          if (this.filtrarTemasPorId(this.infoPage.tema)[0] !== undefined) {
            this.aeDeUnTema = this.filtrarTemasPorId(this.infoPage.tema)[0].titulo;
          }
          this.topics2 = this.filtrarTemasPorMateria(this.materiaSeleccionadaInPage);
          this.topics2.sort(function (a, b) {
            if (a.aprendizaje < b.aprendizaje) { return -1; }
          });
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
    if (this.infoPage.eje !== "") {
      this.ejeSeleccionado = this.infoPage.eje;
      this.temaSeleccionado = this.infoPage.tema;
      this.isAmbit = false;
    } else {
      this.pslSeleccionada = this.infoPage.psl;
      // console.log("PSL SELECCIONADA: ", this.pslSeleccionada);
      this.ambitoSeleccionado = this.infoPage.ambito;
      this.isAmbit = true;
    }

    if (this.isAmbit) {
      for (let i = 0; i < this.ListadoPsl.length; i++) {
        if (this.infoPage.psl === this.ListadoPsl[i].id) {
          this.infoPage.aprendizaje = this.ListadoPsl[i].aprendizaje;
        }
      }
    } else {
      for (let i = 0; i < this.topics.length; i++) {
        if (this.infoPage.tema === this.topics[i].id) {
          this.infoPage.aprendizaje = this.topics[i].aprendizaje;
        }
      }
    }
  }

  ngDoCheck() {
    if (this.isAmbit) {
      for (let i = 0; i < this.ListadoPsl.length; i++) {
        if (this.infoPage.psl === this.ListadoPsl[i].id) {
          this.infoPage.aprendizaje = this.ListadoPsl[i].aprendizaje;
        }
      }
    } else {
      for (let i = 0; i < this.topics.length; i++) {
        if (this.infoPage.tema === this.topics[i].id) {
          this.infoPage.aprendizaje = this.topics[i].aprendizaje;
        }
      }
    }
  }

  seleccionarNivelAcademico() {
    if (this.seccionSeleccionadaInPage !== '') {
      this.grades2 = this.filtrarGradosPorSeccion(this.seccionSeleccionadaInPage);
    } else {
      this.grades2 = [];
    }
  }

  seleccionarGrado() {
    if (this.gradoSeleccionadoInPage !== '') {
      this.subjects2 = this.filtrarMateriasPorGrado(this.gradoSeleccionadoInPage);
    } else {
      this.subjects2 = [];
    }
  }

  seleccionarMateria() {
    if (this.materiaSeleccionadaInPage !== '') {
      this.blocks2 = this.filtrarBloquesPorMateria(this.materiaSeleccionadaInPage);
      this.isAmbit = this.filtrarMateriasPorId(this.materiaSeleccionadaInPage)[0].tiene_ambito;
      if (this.isAmbit) {
        this.ListadoPsl2 = this.filtrarPslPorMateria(this.materiaSeleccionadaInPage);
        this.ListadoPsl2.sort(function (a, b) {
          if (a.aprendizaje < b.aprendizaje) { return -1; }
        });
      } else {
        this.topics2 = this.filtrarTemasPorMateria(this.materiaSeleccionadaInPage);
        this.topics2.sort(function (a, b) {
          if (a.aprendizaje < b.aprendizaje) { return -1; }
        });
      }
    } else {
      this.blocks2 = [];
      this.ListadoPsl2 = [];
    }
  }

  seleccionarBloque() {
    if (this.bloqueSeleccionadaInPage !== '') {
      this.sequences2 = this.filtrarSecuenciasPorBloque(this.bloqueSeleccionadaInPage);
      this.sequences2.sort(function (a, b) {
        if (a.titulo < b.titulo) { return -1; }
      });
    } else {
      this.sequences2 = [];
    }
  }

  seleccionarSecuencia() {
    // if(this.secuenciaSeleccionadaInPage !== ''){
    //   this.pages2 = this.filtrarPaginasPorSecuencia(this.secuenciaSeleccionadaInPage);
    // } else {
    //   this.pages2 = [];
    // }
    this.pages2 = this.filtrarPaginasPorSecuencia(this.secuenciaSeleccionadaInPage);
  }

  filtrarGradosPorSeccion(key: string) {
    return this.grades.filter(object => {
      return object.seccion === key;
    });
  }

  filtrarMateriasPorGrado(key: string) {
    return this.subjects.filter(object => {
      return object.grado === key;
    });
  }

  filtrarMateriasPorId(key: string) {
    return this.subjects.filter(object => {
      return object.id === key;
    });
  }

  filtrarBloquesPorMateria(key: string) {
    return this.blocks.filter(object => {
      return object.materia === key;
    });
  }

  filtrarSecuenciasPorBloque(key: string) {
    return this.sequences.filter(object => {
      return object.bloque === key;
    });
  }

  filtrarSecuenciasPorId(key: string) {
    return this.sequences.filter(object => {
      return object.id === key;
    });
  }

  filtrarPaginasPorSecuencia(key: string) {
    return this.pages.filter(object => {
      return object.secuencia === key;
    });
  }

  filtrarPaginasPorId(key: string) {
    return this.pages.filter(object => {
      return object.id === key;
    });
  }

  filtrarPslPorId(key: string) {
    return this.ListadoPsl.filter(object => {
      return object.id === key;
    });
  }

  filtrarPslPorMateria(key: string) {
    return this.ListadoPsl.filter(object => {
      return object.materia === key;
    });
  }

  filtrarTemasPorId(key: string) {
    return this.topics.filter(object => {
      return object.id === key;
    });
  }

  filtrarTemasPorMateria(key: string) {
    return this.topics.filter(object => {
      return object.materia === key;
    });
  }

  showOptions(boolean) {
    this.options = boolean;
  }

  setVisibility(variable, boolean) {
    if (variable === 'activity') {
      this.hasActivity = boolean;
    }
    if (variable === 'quiz') {
      this.hasQuiz = boolean;
    }
  }

  setImageTag(esParaPagina) {
    // const id = Math.floor((Math.random() * 1000000) + 10000);
    // const valor = this.editorForm.get('editor').value;
    // this.editorForm.get('editor').setValue(`${valor} #imagen id='${id}'#`);
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;
    let numeroPagina;
    if (this.paginaSeleccionada !== "") {
      if (this.pages.length > 0) {
        for (let k = 0; k < this.pages.length; k++) {
          if (this.pages[k].id === this.infoPage.id) {
            // this.infoPage.pagina = this.infoPage.pagina;
            numeroPagina = this.pages[k].pagina;
          }
        }
      }
    } else {
      numeroPagina = 0;
    }

    this.infoPage3.push({
      id: this.infoPage.id,
      paginaSeleccionada: this.paginaSeleccionada,
      pagina: numeroPagina,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado,
      tiene_actividades: this.hasActivity,
      tiene_quiz: this.hasQuiz,
      estado: this.infoPage.estado,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage3[this.infoPage3.length - 1]));
    if (esParaPagina) {
      this.router.navigate(['', 'multimedia', 'images'], { queryParams: { editando: 'true' } });
    } else {
      this.router.navigate(['', 'multimedia', 'images'], { queryParams: { editandoguia: 'true' } });
    }
  }

  setGalleryTag() {
    // const id = Math.floor((Math.random() * 1000000) + 10000);
    // const valor = this.editorForm.get('editor').value;
    // this.editorForm.get('editor').setValue(`${valor} #galeria id='${id}'#`);
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;

    let numeroPagina;
    if (this.paginaSeleccionada !== "") {
      if (this.pages.length > 0) {
        for (let k = 0; k < this.pages.length; k++) {
          if (this.pages[k].id === this.infoPage.id) {
            // this.infoPage.pagina = this.infoPage.pagina;
            numeroPagina = this.pages[k].pagina;
          }
        }
      }
    } else {
      numeroPagina = 0;
    }

    this.infoPage3.push({
      id: this.infoPage.id,
      paginaSeleccionada: this.paginaSeleccionada,
      pagina: numeroPagina,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado,
      tiene_actividades: this.hasActivity,
      tiene_quiz: this.hasQuiz,
      estado: this.infoPage.estado,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage3[this.infoPage3.length - 1]));
    this.router.navigate(['', 'multimedia', 'galleries'], { queryParams: { editando: 'true' } });
  }

  setVideoTag() {
    // const id = Math.floor((Math.random() * 1000000) + 10000);
    // const valor = this.editorForm.get('editor').value;
    // this.editorForm.get('editor').setValue(`${valor} #video id='${id}'#`);
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;

    let numeroPagina;
    if (this.paginaSeleccionada !== "") {
      if (this.pages.length > 0) {
        for (let k = 0; k < this.pages.length; k++) {
          if (this.pages[k].id === this.infoPage.id) {
            // this.infoPage.pagina = this.infoPage.pagina;
            numeroPagina = this.pages[k].pagina;
          }
        }
      }
    } else {
      numeroPagina = 0;
    }

    this.infoPage3.push({
      id: this.infoPage.id,
      paginaSeleccionada: this.paginaSeleccionada,
      pagina: numeroPagina,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado,
      tiene_actividades: this.hasActivity,
      tiene_quiz: this.hasQuiz,
      estado: this.infoPage.estado,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage3[this.infoPage3.length - 1]));
    this.router.navigate(['', 'multimedia', 'videos'], { queryParams: { editando: 'true' } });
  }

  setAudioTag() {
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;

    let numeroPagina;
    if (this.paginaSeleccionada !== "") {
      if (this.pages.length > 0) {
        for (let k = 0; k < this.pages.length; k++) {
          if (this.pages[k].id === this.infoPage.id) {
            // this.infoPage.pagina = this.infoPage.pagina;
            numeroPagina = this.pages[k].pagina;
          }
        }
      }
    } else {
      numeroPagina = 0;
    }
    this.infoPage3.push({
      id: this.infoPage.id,
      paginaSeleccionada: this.paginaSeleccionada,
      pagina: numeroPagina,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado,
      tiene_actividades: this.hasActivity,
      tiene_quiz: this.hasQuiz,
      estado: this.infoPage.estado,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage3[this.infoPage3.length - 1]));
    this.router.navigate(['', 'multimedia', 'audios'], { queryParams: { editando: 'true' } });
  }

  setWikiTag() {
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;

    let numeroPagina;
    if (this.paginaSeleccionada !== "") {
      if (this.pages.length > 0) {
        for (let k = 0; k < this.pages.length; k++) {
          if (this.pages[k].id === this.infoPage.id) {
            // this.infoPage.pagina = this.infoPage.pagina;
            numeroPagina = this.pages[k].pagina;
          }
        }
      }
    } else {
      numeroPagina = 0;
    }

    this.infoPage3.push({
      id: this.infoPage.id,
      paginaSeleccionada: this.paginaSeleccionada,
      pagina: numeroPagina,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado,
      tiene_actividades: this.hasActivity,
      tiene_quiz: this.hasQuiz,
      estado: this.infoPage.estado,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage3[this.infoPage3.length - 1]));
    this.router.navigate(['', 'general', 'wikis'], { queryParams: { editando: 'true' } });
  }

  seleccionarTema(tema) {
    this.aeDeUnTema = this.filtrarTemasPorId(tema)[0].titulo;
  }

  seleccionarPsl(psl) {
    this.aeDeUnPsl = this.filtrarPslPorId(psl)[0].titulo;
  }

  savePage() {
    const valor = this.editorForm.get('editor').value;
    const valor2 = this.editorForm2.get('editor').value;
    // let terminoDepurarActividades = false;
    // let positionActividad = 0;
    const actividadesLocal = this.depurarActividades(this.listOfActivities);
    this.listOfActivities = actividadesLocal;
    if (this.listOfActivities.length === 0) {
      this.hasActivity = false;
    }
    if (this.hasActivity === false) {
      this.listOfActivities = [];
    }
    if (this.quizSelected === '') {
      this.hasQuiz = false;
    }
    //solicitar todos los campos llenos
    if (this.seccionSeleccionadaInPage !== '' && this.gradoSeleccionadoInPage !== '' && this.materiaSeleccionadaInPage !== '' &&
      this.bloqueSeleccionadaInPage !== '' && this.secuenciaSeleccionadaInPage !== '' && valor !== '') {
      this.revisarSiTieneAmbitos('Borrador', valor, valor2);
    } else {
      if (valor === '') {
        this.notify.showWarning('Ingrese contenido a su página', 'Páginas');
      }
      // if(valor2 === ''){
      //   this.notify.showWarning('Ingrese contenido a su guía didáctica', 'Páginas');
      // }
      if (this.secuenciaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una secuencia', 'Páginas');
      }
      if (this.bloqueSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un bloque', 'Páginas');
      }
      if (this.materiaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una materia', 'Páginas');
      }
      if (this.gradoSeleccionadoInPage === '') {
        this.notify.showWarning('Seleccione un grado', 'Páginas');
      }
      if (this.seccionSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un nivel académico', 'Páginas');
      }
    }
  }

  revisarSiTieneAmbitos(estadoPagina, contenido, contenido2) {
    if (this.isAmbit) {
      if (this.ambitoSeleccionado !== '' && this.pslSeleccionada !== '') {
        this.hacerPeticion(estadoPagina, contenido, contenido2);
      } else {
        if (this.pslSeleccionada === '') {
          this.notify.showWarning('Seleccione una PSL', 'Páginas');
        }
        if (this.ambitoSeleccionado === '') {
          this.notify.showWarning('Seleccione un ámbito', 'Páginas');
        }
      }
    } else {
      if (this.ejeSeleccionado !== '' && this.temaSeleccionado !== '') {
        this.hacerPeticion(estadoPagina, contenido, contenido2);
      } else {
        if (this.temaSeleccionado === '') {
          this.notify.showWarning('Seleccione una tema', 'Páginas');
        }
        if (this.ejeSeleccionado === '') {
          this.notify.showWarning('Seleccione un eje', 'Páginas');
        }
      }
    }
  }

  hacerPeticion(estadoPagina, contenido, contenido2) {
    let estado;
    const wikisDepurados = this.depurarArreglo(this.wikisSeleccionados, 'Wiki');
    const wikisVerificados = this.verificarArreglo(wikisDepurados, contenido, 'Wiki');
    const imagenesDepuradas = this.depurarArreglo(this.imagenesSeleccionadas, 'Img');
    const imagenesVerificadas = this.verificarArreglo(imagenesDepuradas, contenido, 'Img');
    const videosDepurados = this.depurarArreglo(this.videosSeleccionados, 'Video');
    const videoVerificados = this.verificarArreglo(videosDepurados, contenido, 'Video');
    const galeriasDepurados = this.depurarArreglo(this.galeriasSeleccionadas, 'Galeria');
    const galeriasVerificadas = this.verificarArreglo(galeriasDepurados, contenido, 'Galeria');
    const audiosDepurados = this.depurarArreglo(this.audiosSeleccionados, 'Audio');
    const audiosVerificados = this.verificarArreglo(audiosDepurados, contenido, 'Audio');
    this.api.actualizarUnaPagina(this.token, this.infoPage.id, estadoPagina, this.seccionSeleccionadaInPage, this.gradoSeleccionadoInPage, this.materiaSeleccionadaInPage, this.bloqueSeleccionadaInPage, this.secuenciaSeleccionadaInPage, this.paginaSeleccionada, this.ambitoSeleccionado, this.pslSeleccionada, this.ejeSeleccionado, this.temaSeleccionado, this.hasActivity, this.listOfActivities, this.hasQuiz, this.quizSelected, contenido, imagenesVerificadas, videoVerificados, audiosVerificados, galeriasVerificadas, wikisVerificados, contenido2, this.imagenesSeleccionadas2).subscribe(respuesta => {
      estado = JSON.parse(JSON.stringify(respuesta)).estado;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError("Error 400", "Página");
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 500) {
        this.notify.showError("Error 500", "Página");
      }
    }, () => {
      if (estado === 200) {
        this.router.navigate(['', 'books', 'pages']);
      } else {
        this.notify.showWarning('Ocurrió un error al actualizar la página', 'Página');
      }
    });
  }

  pushAnActivity() {
    this.listOfActivities.push({
      title: ''
    });
  }

  removeAnActivity(position) {
    this.listOfActivities.splice(position, 1);
  }

  listenTitleOfActivity(name, position) {
    const title = name.target.value;
    this.listOfActivities[position].title = title;
  }

  selectQuiz() {
    const id = Math.floor((Math.random() * 1000000) + 10000);
    this.quizSelected = '' + id;
  }

  unselectQuiz() {
    this.quizSelected = '';
  }

  visualizarPagina(esUnaPagina: Boolean) {
    this.infoPage.secuencia = this.secuenciaSeleccionadaInPage;
    //const infoSecuencia = this.filtrarSecuenciasPorId(this.secuenciaSeleccionadaInPage)[0];
    //this.infoPage.nombreSecuencia = infoSecuencia.titulo;
    if (this.paginaSeleccionada !== "") {
      if (this.pages.length > 0) {
        for (let k = 0; k < this.pages.length; k++) {
          if (this.pages[k].id === this.paginaSeleccionada) {
            // this.infoPage.pagina = this.infoPage.pagina;
            this.infoPage.pagina = this.pages[k].pagina;
          }
        }
      }
    } else {
      this.infoPage.pagina = 0;
    }
    // this.infoPage.pagina = this.filtrarPaginasPorId(this.paginaSeleccionada)[0].pagina;
    if (this.infoPage.ambito !== "") {
      // console.log("AMBITO: SI");
      // Revisar aprendizaje esperado del tema
      const objeto = this.filtrarPslPorId(this.pslSeleccionada)[0];
      this.infoPage.titulo = objeto.titulo;
      this.infoPage.aprendizaje = objeto.aprendizaje;
      this.infoPage.esambito = true;
    } else {
      // console.log("AMBITO: NO");
      const objeto = this.filtrarTemasPorId(this.temaSeleccionado)[0];
      this.infoPage.titulo = objeto.titulo;
      this.infoPage.aprendizaje = objeto.aprendizaje;
      this.infoPage.esambito = false;
    }
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;
    this.contenidoPagina = contenidoEditor;
    this.contenidoPagina2 = contenidoEditor2;
    this.depurarWikis();
    this.depurarImagenes();
    this.depurarVideos();
    this.depurarAudios();
    this.depurarGalerias();
    // ALGORITMO PARA CAMBIAR IMÁGENES, VIDEOS Y GALERÍAS
    // 1. Buscar imágenes del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
    // 2. Buscar videos del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
    // 3. Buscar galerías del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
    // 4. Buscar wikis del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
    // FINALMENTE GUARDAR ESE NUEVO CONTENIDO DE HTML EN LA VARIABLE CONTENIDO
    this.infoPage.contenido2 = this.contenidoPagina;
    this.infoPage.contenido3 = this.contenidoPagina2;
    this.infoPage.espagina = esUnaPagina;
    const actividadesLocal = this.depurarActividades(this.listOfActivities);
    this.listOfActivities = actividadesLocal;
    this.infoPage.actividades = this.listOfActivities;
    localStorage.removeItem('detallePagina4');
    localStorage.setItem('detallePagina4', JSON.stringify(this.infoPage));
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/books/pages/view'])
    );
    window.open(url, '_blank');
  }

  seleccionarWiki() {
    this.router.navigate(['', 'general', 'wikis'], { queryParams: { seleccionar: 'true' } });
  }

  sendToRewiew() {
    const valor = this.editorForm.get('editor').value;
    const valor2 = this.editorForm2.get('editor').value;
    // let terminoDepurarActividades = false;
    // let positionActividad = 0;
    const actividadesLocal = this.depurarActividades(this.listOfActivities);
    this.listOfActivities = actividadesLocal;
    if (this.listOfActivities.length === 0) {
      this.hasActivity = false;
    }
    if (this.hasActivity === false) {
      this.listOfActivities = [];
    }
    if (this.quizSelected === '') {
      this.hasQuiz = false;
    }
    //solicitar todos los campos llenos
    if (this.seccionSeleccionadaInPage !== '' && this.gradoSeleccionadoInPage !== '' && this.materiaSeleccionadaInPage !== '' &&
      this.bloqueSeleccionadaInPage !== '' && this.secuenciaSeleccionadaInPage !== '' && valor !== '') {
      this.revisarSiTieneAmbitos('En revisión', valor, valor2);
    } else {
      if (valor === '') {
        this.notify.showWarning('Ingrese contenido a su página', 'Páginas');
      }
      // if(valor2 === ''){
      //   this.notify.showWarning('Ingrese contenido a su guía didáctica', 'Páginas');
      // }
      if (this.secuenciaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una secuencia', 'Páginas');
      }
      if (this.bloqueSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un bloque', 'Páginas');
      }
      if (this.materiaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una materia', 'Páginas');
      }
      if (this.gradoSeleccionadoInPage === '') {
        this.notify.showWarning('Seleccione un grado', 'Páginas');
      }
      if (this.seccionSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un nivel académico', 'Páginas');
      }
    }
  }

  sendAsRewiewed() {
    const valor = this.editorForm.get('editor').value;
    const valor2 = this.editorForm2.get('editor').value;
    // let terminoDepurarActividades = false;
    // let positionActividad = 0;
    if (this.listOfActivities.length > 0) {
      // do {
      //   if(this.listOfActivities[positionActividad].title === ''){
      //     this.listOfActivities.splice(positionActividad, 1);
      //   }
      //   positionActividad = positionActividad + 1;
      //   if(positionActividad === (this.listOfActivities.length - 1)){
      //     terminoDepurarActividades = true;
      //     if(this.listOfActivities.length === 0){
      //       this.hasActivity = false;
      //     }
      //   }
      // } while(terminoDepurarActividades === false);
    } else {
      this.hasActivity = false;
    }
    if (this.quizSelected === '') {
      this.hasQuiz = false;
    }
    //solicitar todos los campos llenos
    if (this.seccionSeleccionadaInPage !== '' && this.gradoSeleccionadoInPage !== '' && this.materiaSeleccionadaInPage !== '' &&
      this.bloqueSeleccionadaInPage !== '' && this.secuenciaSeleccionadaInPage !== '' && valor !== '') {
      this.revisarSiTieneAmbitos('Revisada', valor, valor2);
    } else {
      if (valor === '') {
        this.notify.showWarning('Ingrese contenido a su página', 'Páginas');
      }
      // if(valor2 === ''){
      //   this.notify.showWarning('Ingrese contenido a su guía didáctica', 'Páginas');
      // }
      if (this.secuenciaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una secuencia', 'Páginas');
      }
      if (this.bloqueSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un bloque', 'Páginas');
      }
      if (this.materiaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una materia', 'Páginas');
      }
      if (this.gradoSeleccionadoInPage === '') {
        this.notify.showWarning('Seleccione un grado', 'Páginas');
      }
      if (this.seccionSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un nivel académico', 'Páginas');
      }
    }
  }

  depurarWikis() {
    // const listadoWikis = this.wikisSeleccionados;
    // for(let m = 0; m < listadoWikis.length; m++){
    //   const nuevoContenido = this.contenidoPagina.replace(`#wikiid='W${listadoWikis[m].id}'#`,`<button type="button" style="border: none;background-color: transparent;outline: none;color: #F09D51;text-decoration: none;font-weight: bold;cursor: pointer;" data-toggle="modal" data-target="#W${listadoWikis[m].id}">${listadoWikis[m].titulo}</button>`);
    //   this.contenidoPagina = nuevoContenido;
    // }
    const wikisDepurados = this.depurarArreglo(this.wikisSeleccionados, 'Wiki');
    const wikisVerificados = this.verificarArreglo(wikisDepurados, this.contenidoPagina, 'Wiki');
    for (let m = 0; m < wikisVerificados.length; m++) {
      const nuevoContenido = this.contenidoPagina.replace(`#wikiid='W${wikisVerificados[m].id}'#`, `<button type="button" style="border: none;background-color: transparent;outline: none;color: #F09D51;text-decoration: none;font-weight: bold;cursor: pointer;" data-toggle="modal" data-target="#W${wikisVerificados[m].id}">${wikisVerificados[m].titulo}</button>`);
      this.contenidoPagina = nuevoContenido;
    }
  }

  depurarImagenes() {
    const imagenesDepuradas = this.depurarArreglo(this.imagenesSeleccionadas, 'Img');
    const imagenesDepuradas2 = this.depurarArreglo(this.imagenesSeleccionadas2, 'Img');
    const imagenesVerificadas = this.verificarArreglo(imagenesDepuradas, this.contenidoPagina, 'Img');
    const imagenesVerificadas2 = this.verificarArreglo(imagenesDepuradas2, this.contenidoPagina2, 'Img');
    for (let m = 0; m < imagenesVerificadas.length; m++) {
      //const nuevoContenido = this.contenidoPagina.replace(`#imagenid='I${listadoImagenes[m].id}'#`,`<br/><img src="${listadoImagenes[m].src}" class="img-fluid pl-5 pr-5"><p class="mt-1 mb-2 text-center">${listadoImagenes[m].descripcion}</p><br/>`);
      let texto = '';
      if (imagenesVerificadas[m].descripcion !== '') {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas[m].id}"><img src="${imagenesVerificadas[m].src}" class="img-fluid pl-0 pr-0"></a><p class="mt-1 mb-2 text-center">${imagenesVerificadas[m].descripcion}</p><br/>`;
      } else {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas[m].id}"><img src="${imagenesVerificadas[m].src}" class="img-fluid pl-0 pr-0"></a><br/>`;
      }
      const nuevoContenido = this.contenidoPagina.replace(`#imagenid='I${imagenesVerificadas[m].id}'#`, texto);
      this.contenidoPagina = nuevoContenido;
    }
    for (let m = 0; m < imagenesVerificadas2.length; m++) {
      //const nuevoContenido = this.contenidoPagina.replace(`#imagenid='I${listadoImagenes[m].id}'#`,`<br/><img src="${listadoImagenes[m].src}" class="img-fluid pl-5 pr-5"><p class="mt-1 mb-2 text-center">${listadoImagenes[m].descripcion}</p><br/>`);
      let texto = '';
      if (imagenesVerificadas2[m].descripcion !== '') {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas2[m].id}"><img src="${imagenesVerificadas2[m].src}" class="img-fluid pl-5 pr-5"></a><p class="mt-1 mb-2 text-center">${imagenesVerificadas2[m].descripcion}</p><br/>`;
      } else {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas2[m].id}"><img src="${imagenesVerificadas2[m].src}" class="img-fluid pl-5 pr-5"></a><br/>`;
      }
      const nuevoContenido = this.contenidoPagina2.replace(`#imagenid='I${imagenesVerificadas2[m].id}'#`, texto);
      this.contenidoPagina2 = nuevoContenido;
    }
  }

  depurarVideos() {
    const videosDepurados = this.depurarArreglo(this.videosSeleccionados, 'Video');
    const videoVerificados = this.verificarArreglo(videosDepurados, this.contenidoPagina, 'Video');
    for (let m = 0; m < videoVerificados.length; m++) {
      let texto = '';
      const nombreConExtension = `${videoVerificados[m].id}${videoVerificados[m].src.substring(videoVerificados[m].src.length - 4, videoVerificados[m].src.length)}`;
      const extensionFile = nombreConExtension.substring(nombreConExtension.length - 3, nombreConExtension.length);
      const urlBase = videoVerificados[m].src.split('/')[2];
      if (videoVerificados[m].descripcion !== '') {

        //texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/uploads/${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><p class="mt-2 mb-2 text-center">${videoVerificados[m].descripcion}</p><br/>`;
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/api/v1.0.0/ebe/video/stream?video=${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><p class="mt-2 mb-2 text-center">${videoVerificados[m].descripcion}</p><br/>`;
      } else {
        //texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/api/v1.0.0/ebe/video/stream?video=${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><br/>`;
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/uploads/${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><br/>`;

      }
      const nuevoContenido = this.contenidoPagina.replace(`#videoid='V${videoVerificados[m].id}'#`, texto);
      this.contenidoPagina = nuevoContenido;
    }
  }

  depurarGalerias() {
    // const listadoGalerias = this.galeriasSeleccionadas;
    // for(let m = 0; m < listadoGalerias.length; m++){
    //   let carousel = '';
    //   for(let k = 0; k < listadoGalerias[m].imagenes.length; k++){
    //     if(k === 0){
    //       carousel = carousel.concat(carousel, ' ', `<div class="carousel-item active"><img src="${listadoGalerias[m].imagenes[k].src}" class="d-block w-100"></div>`);
    //     } else if(k > 0){
    //       let carousel2 = '';
    //       carousel = carousel2.concat(carousel, ' ', `<div class="carousel-item"><img src="${listadoGalerias[m].imagenes[k].src}" class="d-block w-100"></div>`);
    //     }
    //   }
    //   const nuevoContenido = this.contenidoPagina.replace(`#galeriaid='G${listadoGalerias[m].id}'#`,`<div id="G${listadoGalerias[m].id}" class="carousel slide" data-ride="carousel"><div class="carousel-inner">${carousel}</div><a class="carousel-control-prev" href="#G${listadoGalerias[m].id}" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span></a><a class="carousel-control-next" href="#G${listadoGalerias[m].id}" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span></a></div><p class="mt-2 text-center">${listadoGalerias[m].descripcion}</p>`);
    //   this.contenidoPagina = nuevoContenido;
    // }
    const galeriasDepurados = this.depurarArreglo(this.galeriasSeleccionadas, 'Galeria');
    const galeriasVerificadas = this.verificarArreglo(galeriasDepurados, this.contenidoPagina, 'Galeria');
    // const listadoGalerias = this.galeriasSeleccionadas;
    for (let m = 0; m < galeriasVerificadas.length; m++) {
      let carousel = '';
      let contador = 0;
      for (let k = 0; k < galeriasVerificadas[m].imagenes.length; k++) {
        contador = contador + 1;
        if (k === 0) {
          carousel = carousel.concat(carousel, ' ', `<div class="carousel-item active"><img src="${galeriasVerificadas[m].imagenes[k].src}" class="d-block w-100"></div>`);
        } else if (k > 0) {
          let carousel2 = '';
          carousel = carousel2.concat(carousel, ' ', `<div class="carousel-item"><img src="${galeriasVerificadas[m].imagenes[k].src}" class="d-block w-100"></div>`);
        }
      }
      if (contador === galeriasVerificadas[m].imagenes.length) {
        let nuevoContenido = '';
        if (galeriasVerificadas[m].descripcion !== '') {
          nuevoContenido = this.contenidoPagina.replace(`#galeriaid='G${galeriasVerificadas[m].id}'#`, `<div id="G${galeriasVerificadas[m].id}" class="carousel slide" data-ride="carousel"><div class="carousel-inner">${carousel}</div><a class="carousel-control-prev" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span></a><a class="carousel-control-next" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span></a></div><p class="mt-2 text-center">${galeriasVerificadas[m].descripcion}</p>`);
        } else {
          nuevoContenido = this.contenidoPagina.replace(`#galeriaid='G${galeriasVerificadas[m].id}'#`, `<div id="G${galeriasVerificadas[m].id}" class="carousel slide" data-ride="carousel"><div class="carousel-inner">${carousel}</div><a class="carousel-control-prev" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span></a><a class="carousel-control-next" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span></a></div>`);
        }
        this.contenidoPagina = nuevoContenido;
      }
    }
  }

  depurarAudios() {
    const audiosDepurados = this.depurarArreglo(this.audiosSeleccionados, 'Audio');
    const audiosVerificados = this.verificarArreglo(audiosDepurados, this.contenidoPagina, 'Audio');
    for (let m = 0; m < audiosVerificados.length; m++) {
      let texto = '';
      const nombreConExtension = `${audiosVerificados[m].id}${audiosVerificados[m].src.substring(audiosVerificados[m].src.length - 5, audiosVerificados[m].src.length)}`;
      const urlBase = audiosVerificados[m].src.split('/')[2];
      if (audiosVerificados[m].descripcion !== '') {
        texto = `<br/><audio controls class="mt-2 pl-0 pr-0" style="width: 100%;" controlsList="nodownload"><source src="https://${urlBase}/api/v1.0.0/ebe/audio/stream?audio=${nombreConExtension}">Lo sentimos, su navegador no admite audios incrustados.</audio><p class="mt-2 mb-2 text-center">${audiosVerificados[m].descripcion}</p><br/>`;
      } else {
        texto = `<br/><audio controls class="mt-2 pl-0 pr-0" style="width: 100%;" controlsList="nodownload"><source src="https://${urlBase}/api/v1.0.0/ebe/audio/stream?audio=${nombreConExtension}">Lo sentimos, su navegador no admite audios incrustados.</audio><br/>`;
      }
      const nuevoContenido = this.contenidoPagina.replace(`#audioid='A${audiosVerificados[m].id}'#`, texto);
      this.contenidoPagina = nuevoContenido;
    }
  }

  depurarActividades(actividades) {
    let actividadesLocal = [];
    let contador = 0;
    for (let i = 0; i < actividades.length; i++) {
      contador = contador + 1;
      if (actividades[i].title !== '') {
        actividadesLocal.push({
          title: actividades[i].title
        });
      }
    }
    if (contador === actividades.length) {
      return actividadesLocal;
    }
  }

  depurarArreglo(arreglo, tipo) {
    let arregloLocal = [];
    if (tipo === 'Wiki') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        contador = contador + 1;
        const buscarId = this.filtrarArreglo(arregloLocal, arreglo[i].id);
        // console.log("BUSCAR ID WIKI: ", buscarId);
        if (buscarId.length === 0) {
          arregloLocal.push({
            id: arreglo[i].id,
            titulo: arreglo[i].titulo,
            descripcion: arreglo[i].descripcion,
            count: 0
          });
        }
      }
      if (contador === arreglo.length) {
        let contador2 = 0;
        for (let k = 0; k < arregloLocal.length; k++) {
          contador2 = contador2 + 1;
          const contadorId = this.filtrarArreglo(arreglo, arregloLocal[k].id);
          arregloLocal[k].count = contadorId.length;
        }
        if (contador2 === arregloLocal.length) {
          return arregloLocal;
        }
      }
    } else if (tipo === 'Img' || tipo === 'Video' || tipo === 'Audio') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        contador = contador + 1;
        const buscarId = this.filtrarArreglo(arregloLocal, arreglo[i].id);
        if (buscarId.length === 0) {
          arregloLocal.push({
            id: arreglo[i].id,
            src: arreglo[i].src,
            descripcion: arreglo[i].descripcion,
            count: 0
          });
        }
      }
      if (contador === arreglo.length) {
        // return arregloLocal;
        let contador2 = 0;
        for (let k = 0; k < arregloLocal.length; k++) {
          contador2 = contador2 + 1;
          const contadorId = this.filtrarArreglo(arreglo, arregloLocal[k].id);
          arregloLocal[k].count = contadorId.length;
        }
        if (contador2 === arregloLocal.length) {
          return arregloLocal;
        }
      }
    } else if (tipo === 'Galeria') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        contador = contador + 1;
        const buscarId = this.filtrarArreglo(arregloLocal, arreglo[i].id);
        if (buscarId.length === 0) {
          arregloLocal.push({
            id: arreglo[i].id,
            descripcion: arreglo[i].descripcion,
            imagenes: arreglo[i].imagenes,
            count: 0
          });
        }
      }
      if (contador === arreglo.length) {
        // return arregloLocal;
        let contador2 = 0;
        for (let k = 0; k < arregloLocal.length; k++) {
          contador2 = contador2 + 1;
          const contadorId = this.filtrarArreglo(arreglo, arregloLocal[k].id);
          arregloLocal[k].count = contadorId.length;
        }
        if (contador2 === arregloLocal.length) {
          return arregloLocal;
        }
      }
    }
  }

  filtrarArreglo(listado, key) {
    return listado.filter(object => {
      return object.id === key;
    });
  }

  verificarArreglo(listado, contenido, tipo) {
    let arregloDepurado = listado;
    let contador = 0;
    for (let i = 0; i < arregloDepurado.length; i++) {
      contador = contador + 1;
      let matchEnContenido = [];
      if (tipo === 'Wiki') {
        matchEnContenido = this.matchWikis(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Img') {
        matchEnContenido = this.matchImages(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Video') {
        matchEnContenido = this.matchVideos(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Audio') {
        matchEnContenido = this.matchAudios(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Galeria') {
        matchEnContenido = this.matchGalleries(arregloDepurado[i].id, contenido);
      }
      if (arregloDepurado[i].count !== matchEnContenido.length) {
        arregloDepurado[i].count = matchEnContenido.length;
      }
    }
    if (contador === arregloDepurado.length) {
      // Rellenar el arreglo con la nueva cantidad
      return this.rellenarArreglo(arregloDepurado, tipo);
    }
  }

  rellenarArreglo(arreglo, tipo) {
    let arregloLocal = [];
    if (tipo === 'Wiki') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        let contador2 = 0;
        for (let k = 0; k < arreglo[i].count; k++) {
          contador2 = contador2 + 1;
          arregloLocal.push({
            id: arreglo[i].id,
            titulo: arreglo[i].titulo,
            descripcion: arreglo[i].descripcion
          });
        }
        if (contador2 === arreglo[i].count) {
          contador = contador + 1;
        }
      }
      if (contador === arreglo.length) {
        return arregloLocal;
      }
    } else if (tipo === 'Img' || tipo === 'Video' || tipo === 'Audio') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        let contador2 = 0;
        for (let k = 0; k < arreglo[i].count; k++) {
          contador2 = contador2 + 1;
          arregloLocal.push({
            id: arreglo[i].id,
            src: arreglo[i].src,
            descripcion: arreglo[i].descripcion
          });
        }
        if (contador2 === arreglo[i].count) {
          contador = contador + 1;
        }
      }
      if (contador === arreglo.length) {
        return arregloLocal;
      }
    } else if (tipo === 'Galeria') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        let contador2 = 0;
        for (let k = 0; k < arreglo[i].count; k++) {
          contador2 = contador2 + 1;
          arregloLocal.push({
            id: arreglo[i].id,
            descripcion: arreglo[i].descripcion,
            imagenes: arreglo[i].imagenes
          });
        }
        if (contador2 === arreglo[i].count) {
          contador = contador + 1;
        }
      }
      if (contador === arreglo.length) {
        return arregloLocal;
      }
    }
  }

  matchWikis(wikiId, contenido) {
    const regex = new RegExp(`#wikiid='W${wikiId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchImages(imageId, contenido) {
    const regex = new RegExp(`#imagenid='I${imageId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchGalleries(galeriaId, contenido) {
    const regex = new RegExp(`#galeriaid='G${galeriaId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchVideos(videoId, contenido) {
    const regex = new RegExp(`#videoid='V${videoId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchAudios(videoId, contenido) {
    const regex = new RegExp(`#audioid='A${videoId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

}
