import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-list-of-academic-levels',
  templateUrl: './list-of-academic-levels.component.html',
  styleUrls: ['./list-of-academic-levels.component.css']
})
export class ListOfAcademicLevelsComponent implements OnInit {
  public id = '';
  private id2 = '';
  private position = 0;
  public nameOfAcademicLevel = '';
  public nameOfAcademicLevel2 = '';
  private nameOfAcademicLevel3 = '';
  public sections = [];
  public permisoAgregar = false;
  public permisoEditar = false;
  public permisoEliminar = false;
  private token = '';
  opciones: NgbModalOptions;
  resultado: string;
  
  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if(!tokenEspirado && this.token !== null){
      this.permisoAgregar = info.permisos[0].agregar_niveles_académicos;
      this.permisoEditar = info.permisos[0].actualizar_niveles_académicos;
      this.permisoEliminar = info.permisos[0].eliminar_niveles_académicos;
      if(info.permisos[0].visualizar_niveles_académicos === true){
        let listado;
        this.api.obtenerListadoSecciones(this.token).subscribe(respuesta => {
          listado = JSON.parse(JSON.stringify(respuesta)).secciones;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Secciones");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Secciones");
          }
        }, () => {
          this.sections = listado;
          this.notify.showInfo("Información actualizada", "Niveles académicos");
        });
      } else if(info.permisos[0].agregar_niveles_académicos !== true){
        this.router.navigate(['', 'auth']);
      }
    } else {
      this.router.navigate(['', 'auth']);
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      //console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return  `${reason}`;
    }
  }

  listenNameOfAcademicLevel(name){
    name.target.value = name.target.value.toUpperCase();
    this.nameOfAcademicLevel = name.target.value;
    this.nameOfAcademicLevel2 = name.target.value;
  }

  listenIdOfAcademicLevel(id){
    this.id2 = id.target.value;
  }

  agregarNivelAcademico(){
    if(this.nameOfAcademicLevel.trim() !== ''){
      let idRespuesta;
      this.api.agregarUnaSeccion(this.token, this.nameOfAcademicLevel.trim().toUpperCase()).subscribe(respuesta => {
        idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Secciones');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro nivel académico. Prueba con otro diferente', 'Secciones');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Secciones');
        }
      }, () => {
        this.notify.showInfo('Se agregó el nivel correctamente', 'Secciones');
        this.sections.push({
          id: idRespuesta,
          nombre: this.nameOfAcademicLevel.trim().toUpperCase(),
          utilizado: 0
        });
      });
    } else {
      this.notify.showWarning('No puedes agregar niveles académicos sin nombre', 'Secciones');
    }
  }

  mostrarNivelAcademico(idNivel, nombreNivel, posicionNivel){
    this.id = idNivel;
    this.nameOfAcademicLevel2 = nombreNivel;
    this.nameOfAcademicLevel3 = nombreNivel;
    this.position = posicionNivel;
  }

  actualizarNivelAcademico(){
    if(this.nameOfAcademicLevel2.trim() !== this.nameOfAcademicLevel3.trim()){
      let estadoRespuesta;
      this.api.actualizarUnaSeccion(this.token, this.id, this.nameOfAcademicLevel2.trim().toUpperCase()).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Secciones');
        }
        if (err.status === 401 || err.status === 404) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro nivel académico. Prueba con otro diferente', 'Secciones');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Secciones');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.notify.showInfo('Se actualizó el nivel correctamente', 'Secciones');
          this.sections[this.position].nombre = this.nameOfAcademicLevel2.trim().toUpperCase();
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Secciones');
        }
      });
    } else {
      this.notify.showWarning('Los nombres no pueden ser los mismos', 'Secciones');
    }
  }

  eliminarNivelAcademico(){
    if(this.id2.trim() === this.id.trim()){
      let estadoRespuesta;
      this.api.eliminarUnaSeccion(this.token, this.id).subscribe(respuesta => {
        estadoRespuesta = JSON.parse(JSON.stringify(respuesta)).estado;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          //console.log('Client-side error occured.');
        } else {
          //console.log('Server-side error occured.');
        }
        if (err.status === 400) {
          this.notify.showError('Error 400', 'Secciones');
        }
        if (err.status === 401) {
          this.auth.eliminarToken();
          this.router.navigate(['', 'auth']);
        }
        if (err.status === 404) {
          this.notify.showWarning('El nivel que intentas eliminar no existe', 'Secciones');
        }
        if (err.status === 403) {
          this.notify.showWarning('El nombre ya existe en otro nivel académico. Prueba con otro diferente', 'Secciones');
        }
        if (err.status === 500) {
          this.notify.showError('Error 500', 'Secciones');
        }
      }, () => {
        if(estadoRespuesta === 200){
          this.sections.splice(this.position, 1);
          this.notify.showInfo('Se eliminó correctamente el nivel', 'Secciones');
        } else {
          this.notify.showError('Ocurrió un error al procesar la solicitud', 'Secciones');
        }
      });
    } else {
      this.notify.showWarning('Verifica que el ID esté bien escrito', 'Secciones');
    }
  }





}
