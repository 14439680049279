import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './components/auth/signin/signin.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { Error404Component } from './components/errors/error404/error404.component';
import { ListOfAcademicLevelsComponent } from './components/general/academic-levels/list-of-academic-levels/list-of-academic-levels.component';
import { SchoolGradeListComponent } from './components/general/school-grades/school-grade-list/school-grade-list.component';
import { ListOfSchoolSubjectsComponent } from './components/general/school-subjects/list-of-school-subjects/list-of-school-subjects.component';
import { ListOfAmbitsComponent } from './components/general/ambits/list-of-ambits/list-of-ambits.component';
import { ListOfSocialPracticesOfLanguageComponent } from './components/general/social-practices-of-language/list-of-social-practices-of-language/list-of-social-practices-of-language.component';
import { ListOfCurricularAxesComponent } from './components/general/curricular-axes/list-of-curricular-axes/list-of-curricular-axes.component';
import { ListOfBlocksOfASubjectComponent } from './components/general/blocks-of-a-subject/list-of-blocks-of-a-subject/list-of-blocks-of-a-subject.component';
import { ListOfSequencesOfASubjectComponent } from './components/general/sequences-of-a-subject/list-of-sequences-of-a-subject/list-of-sequences-of-a-subject.component';
import { ListOfTopicsInASubjectComponent } from './components/general/topics-in-a-subject/list-of-topics-in-a-subject/list-of-topics-in-a-subject.component';
import { ListOfPagesOfATopicComponent } from './components/general/pages-of-a-topic/list-of-pages-of-a-topic/list-of-pages-of-a-topic.component';
import { AddAPageToATopicComponent } from './components/general/pages-of-a-topic/add-a-page-to-a-topic/add-a-page-to-a-topic.component';
import { EditATopicPageComponent } from './components/general/pages-of-a-topic/edit-a-topic-page/edit-a-topic-page.component';
import { ListOfWikisComponent } from './components/general/wikis/list-of-wikis/list-of-wikis.component';
import { ListOfQuizzesComponent } from './components/general/quizzes/list-of-quizzes/list-of-quizzes.component';
import { MultimediaImagesComponent } from './components/general/multimedia/multimedia-images/multimedia-images.component';
import { MultimediaVideosComponent } from './components/general/multimedia/multimedia-videos/multimedia-videos.component';
import { MultimediaGalleriesComponent } from './components/general/multimedia/multimedia-galleries/multimedia-galleries.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from 'src/environments/environment.prod';
import { ViewPageOfATopicComponent } from './components/general/pages-of-a-topic/view-page-of-a-topic/view-page-of-a-topic.component';
import { SafeHtmlPipe } from './pipes/safehtml/safe-html.pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { MultimediaAudiosComponent } from './components/general/multimedia/multimedia-audios/multimedia-audios.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  paramName: 'file',
  url: 'https://jenios.mx',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Jenios ${environment.publicJenios}`,
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    Error404Component,
    ListOfAcademicLevelsComponent,
    SchoolGradeListComponent,
    ListOfSchoolSubjectsComponent,
    ListOfAmbitsComponent,
    ListOfSocialPracticesOfLanguageComponent,
    ListOfCurricularAxesComponent,
    ListOfBlocksOfASubjectComponent,
    ListOfSequencesOfASubjectComponent,
    ListOfTopicsInASubjectComponent,
    ListOfPagesOfATopicComponent,
    AddAPageToATopicComponent,
    EditATopicPageComponent,
    ListOfWikisComponent,
    ListOfQuizzesComponent,
    MultimediaImagesComponent,
    MultimediaVideosComponent,
    MultimediaGalleriesComponent,
    ViewPageOfATopicComponent,
    SafeHtmlPipe,
    MultimediaAudiosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],                               // remove formatting button
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          [{ 'align': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        ]
      }
    }),
    ToastrModule.forRoot(),
    DropzoneModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ],
  exports: [
    SafeHtmlPipe
],
  bootstrap: [AppComponent]
})
export class AppModule { }
