import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestfulApiService } from '../../../services/restful/restful-api.service';
import { AuthServiceService } from '../../../services/auth/auth-service.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  private clave = '';
  private secreto = '';
  constructor(private router: Router, private api: RestfulApiService, private auth: AuthServiceService, private notify: ToastNotificationService) { }

  ngOnInit() {
    this.auth.eliminarToken();
  }

  escucharUsuario(usuario){
    this.clave = usuario.target.value;
  }
  escucharSecreto(secreto){
    this.secreto = secreto.target.value;
  }

  signIn(){
    let token;
    this.api.autenticarmeComoEspecialista(this.clave.trim(), this.secreto.trim()).subscribe(respuesta => {
      token = JSON.parse(JSON.stringify(respuesta)).token;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError("Error 400", "Autenticación");
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.notify.showWarning("Usuario no autorizado", "Autenticación");
      }
      if (err.status === 500) {
        this.notify.showError("Error 500", "Autenticación");
      }
    }, () => {
      this.auth.guardarToken(token);
      const helper = new JwtHelperService();
      const info = helper.decodeToken(token);
      if(info.permisos[0].visualizar_niveles_académicos === true || info.permisos[0].agregar_niveles_académicos === true){
        this.router.navigate(['', 'general', 'levels']);
      } else if(info.permisos[0].visualizar_grados_de_estudio === true || info.permisos[0].agregar_grados_de_estudio === true){
        this.router.navigate(['', 'general', 'grades']);
      } else if(info.permisos[0].visualizar_materias === true || info.permisos[0].agregar_materias === true){
        this.router.navigate(['', 'general', 'subjects']);
      } else if(info.permisos[0].visualizar_wikis === true || info.permisos[0].agregar_wikis === true){
        this.router.navigate(['', 'general', 'wikis']);
      } else if(info.permisos[0].visualizar_quizzes === true){
        this.router.navigate(['', 'general', 'quizzes']);
      } else if(info.permisos[0].visualizar_bloques === true || info.permisos[0].agregar_bloques === true){
        this.router.navigate(['', 'organizers', 'blocks']);
      }  else if(info.permisos[0].visualizar_ambitos === true || info.permisos[0].agregar_ambitos === true){
        this.router.navigate(['', 'organizers', 'ambits']);
      } else if(info.permisos[0].visualizar_ejes === true || info.permisos[0].agregar_ejes === true){
        this.router.navigate(['', 'organizers', 'axes']);
      } else if(info.permisos[0].visualizar_psls === true || info.permisos[0].agregar_psls === true){
        this.router.navigate(['', 'organizers', 'social-practices']);
      } else if(info.permisos[0].visualizar_temas === true || info.permisos[0].agregar_temas === true){
        this.router.navigate(['', 'organizers', 'topics']);
      } else if(info.permisos[0].visualizar_secuencias === true || info.permisos[0].agregar_secuencias === true){
        this.router.navigate(['', 'books', 'sequences']);
      } else if(info.permisos[0].visualizar_paginas === true || info.permisos[0].agregar_paginas === true){
        this.router.navigate(['', 'books', 'pages']);
      } else if(info.permisos[0].visualizar_imagenes === true || info.permisos[0].agregar_imagenes === true){
        this.router.navigate(['', 'multimedia', 'images']);
      } else if(info.permisos[0].visualizar_galerias === true || info.permisos[0].agregar_galerias === true){
        this.router.navigate(['', 'multimedia', 'galleries']);
      } else if(info.permisos[0].visualizar_videos === true || info.permisos[0].agregar_videos === true){
        this.router.navigate(['', 'multimedia', 'videos']);
      } else {
        this.auth.eliminarToken();
        this.notify.showWarning("Usuario no autorizado", "Autenticación");
      }
    });
  }

}
