export const environment = {
  production: true,
  // url_principal: 'https://sandbox.gateway.ebe.jenios.mx/v1.0.0',
  //url_principal: 'https://gateway.ebe.jenios.mx/v1.0.0',
  url_principal: 'https://api.ebe-smart.app/v1.0.0',
  url_jenios: 'https://api.jenios.mx/jenios/v1.0.0',
  public: 'V9gAYQwse18WvoPjBbr5ebvCg1EzWOaOQC5c5lR2lSnJYyTky664kA14e9HoMw4ciuvAiBxN30wRavmT8CIq30V0LnQ9cgANIqBgtNIQqoiniFCk1zdQVK8GIoqtiptZ',
  publicJenios: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoiRkNrVTFvVXhBd0gyQ0NXOUszR2ZMWWdFQlZYSjBnYTMiLCJpYXQiOjE2MDY1ODIxNjIsImF1ZCI6Imh0dHBzOi8vZWJlLmplbmlvcy5teCJ9.FdzBf6ZGyH6ViwFTXpP2hzHzawhPmyGtRjTWmpuG_Ng',
  publicKeyJenios: 'FCkU1oUxAwH2CCW9K3GfLYgEBVXJ0ga3',
  // url_bucket: 'https://f1.s1.jenios.mx',
  url_bucket: 'https://bucket.ebe-smart.app',
  url_quizzes: 'https://quiz.ebe.jenios.mx'
};
