import { Component, OnInit } from '@angular/core';
import { Router  } from '@angular/router';
import * as $ from 'jquery';
import { NavbarComponent } from '../navbar/navbar.component';
import { RestfulApiService } from '../../../services/restful/restful-api.service';
import { AuthServiceService } from '../../../services/auth/auth-service.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  private permisos;
  private routing = [];
  public isGeneral = false;
  public isLevel = false;
  public isGrade = false;
  public isSubject = false;
  public isWiki = false;
  public isQuiz = false;
  public isBooks = false;
  public isOrganizer = false;
  public isMultimedia = false;
  public isBlock = false;
  public isSequence = false;
  public isAmbit = false;
  public isPSL = false;
  public isAxe = false;
  public isTopic = false;
  public isPage = false;
  public isImage = false;
  public isVideo = false;
  public isAudio = false;
  public isGallery = false;
  public toggleSidebar = false;
  public opcionGeneral = 0;
  public opcionGeneralNiveles = false;
  public opcionGeneralGrados = false;
  public opcionGeneralMaterias = false;
  public opcionGeneralWikis = false;
  public opcionGeneralQuizzes = false;
  public opcionContenidos = 0;
  public opcionContenidosBloques = false;
  public opcionContenidosSecuencias = false;
  public opcionContenidosAmbitos = false;
  public opcionContenidosPSL = false;
  public opcionContenidosEjes = false;
  public opcionContenidosTemas = false;
  public opcionContenidosPaginas = false;
  public opcionMultimedia = 0;
  public opcionMultimediaImagenes = false;
  public opcionMultimediaVideos = false;
  public opcionMultimediaAudios = false;
  public opcionMultimediaGalerias = false;
  private token = '';

  constructor(private router: Router, private api: RestfulApiService, private auth: AuthServiceService) {
  }

  ngOnInit() {
    const token = this.auth.obtenertoken();
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(token);
    const info = helper.decodeToken(token);
    if(!tokenEspirado && token !== null){
      this.permisos = info.permisos[0];
    } else {
      this.router.navigate(['', 'auth']);
    }
    // Ocultar opciones del sidebar según los permisos del usuario
    // GENERALES
    if(this.permisos.visualizar_niveles_académicos === true || this.permisos.agregar_niveles_académicos === true){
      this.opcionGeneralNiveles = true;
      this.opcionGeneral = this.opcionGeneral + 1;
    }
    if(this.permisos.visualizar_grados_de_estudio === true || this.permisos.agregar_grados_de_estudio === true){
      this.opcionGeneralGrados = true;
      this.opcionGeneral = this.opcionGeneral + 1;
    }
    if(this.permisos.visualizar_materias === true || this.permisos.agregar_materias === true){
      this.opcionGeneralMaterias = true;
      this.opcionGeneral = this.opcionGeneral + 1;
    }
    if(this.permisos.visualizar_wikis === true || this.permisos.agregar_wikis === true){
      this.opcionGeneralWikis = true;
      this.opcionGeneral = this.opcionGeneral + 1;
    }
    if(this.permisos.visualizar_quizzes === true){
      this.opcionGeneralQuizzes = true;
      this.opcionGeneral = this.opcionGeneral + 1;
    }
    // CONTENIDOS
    if(this.permisos.visualizar_bloques === true || this.permisos.agregar_bloques === true){
      this.opcionContenidosBloques = true;
      this.opcionContenidos = this.opcionContenidos + 1;
    }
    if(this.permisos.visualizar_secuencias === true || this.permisos.agregar_secuencias === true){
      this.opcionContenidosSecuencias = true;
      this.opcionContenidos = this.opcionContenidos + 1;
    }
    if(this.permisos.visualizar_ambitos === true || this.permisos.agregar_ambitos === true){
      this.opcionContenidosAmbitos = true;
      this.opcionContenidos = this.opcionContenidos + 1;
    }
    if(this.permisos.visualizar_psls === true || this.permisos.agregar_psls === true){
      this.opcionContenidosPSL = true;
      this.opcionContenidos = this.opcionContenidos + 1;
    }
    if(this.permisos.visualizar_ejes === true || this.permisos.agregar_ejes === true){
      this.opcionContenidosEjes = true;
      this.opcionContenidos = this.opcionContenidos + 1;
    }
    if(this.permisos.visualizar_temas === true || this.permisos.agregar_temas === true){
      this.opcionContenidosTemas = true;
      this.opcionContenidos = this.opcionContenidos + 1;
    }
    if(this.permisos.visualizar_paginas === true || this.permisos.agregar_paginas === true){
      this.opcionContenidosPaginas = true;
      this.opcionContenidos = this.opcionContenidos + 1;
    }
    // MULTIMEDIA
    if(this.permisos.visualizar_imagenes === true || this.permisos.agregar_imagenes === true){
      this.opcionMultimediaImagenes = true;
      this.opcionMultimedia = this.opcionMultimedia + 1;
    }
    if(this.permisos.visualizar_galerias === true || this.permisos.agregar_galerias === true){
      this.opcionMultimediaGalerias = true;
      this.opcionMultimedia = this.opcionMultimedia + 1;
    }
    if(this.permisos.visualizar_videos === true || this.permisos.agregar_videos === true){
      this.opcionMultimediaVideos = true;
      this.opcionMultimedia = this.opcionMultimedia + 1;
    }
    if(this.permisos.visualizar_audios === true || this.permisos.agregar_audios === true){
      this.opcionMultimediaAudios = true;
      this.opcionMultimedia = this.opcionMultimedia + 1;
    }
    
    // Escuchar ruta
    this.routing = this.router.url.split('/');
    // Switch de rutas para efectos visuales del sidebar
    switch(this.routing[1]) {
      case 'general':
        this.isGeneral = true;
        switch(this.routing[2]){
          case 'levels':
            this.isLevel = true;
          break;
          case 'grades':
            this.isGrade = true;
          break;
          case 'subjects':
            this.isSubject = true;
          break;
          case 'wikis':
            this.isWiki = true;
          break;
          case 'quizzes':
            this.isQuiz = true;
          break;
        }
      break;
      case 'organizers':
        this.isOrganizer = true;
        switch(this.routing[2]){
          case 'blocks':
            this.isBlock = true;
          break;
          case 'ambits':
            this.isAmbit = true;
          break;
          case 'social-practices':
            this.isPSL = true;
          break;
          case 'axes':
            this.isAxe = true;
          break;
          case 'topics':
            this.isTopic = true;
          break;
        }
      break;
      case 'books':
        this.isBooks = true;
        switch(this.routing[2]){
          case 'sequences':
            this.isSequence = true;
          break;
          case 'pages':
            this.isPage = true;
          break;
        }
      break;
      case 'multimedia':
        this.isMultimedia = true;
        switch(this.routing[2]){
          case 'images':
            this.isImage = true;
          break;
          case 'galleries':
            this.isGallery = true;
          break;
          case 'videos':
            this.isVideo = true;
          break;
          case 'audios':
            this.isAudio = true;
          break;
        }
      break;
    }
    // aplicar estilo para ocultar sidebar y mostrar cuando pasa el cursor
    const body = $('body');
    if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
      body.toggleClass('sidebar-hidden');
    } else {
      body.toggleClass('sidebar-icon-only');
    }

  }

  ngDoCheck(){
    this.api.currentSidebar.subscribe(action => this.toggleSidebar = action);
    if(this.toggleSidebar){
      $('.sidebar-offcanvas').addClass('active');
    } else {
      $('.sidebar-offcanvas').removeClass('active');
    }
  }

  goToQuizzes(){
    // window.open(`${environment.url_quizzes}/verify/${this.token}`, "_blank");
    window.open(`${environment.url_quizzes}/verify/notieneseguridad`, "_blank");
    // window.open(`${environment.url_quizzes}/verify?token=${this.token}`, "_blank");
  }

}
