import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthServiceService } from 'src/app/services/auth/auth-service.service';
import { RestfulApiService } from '../../../../services/restful/restful-api.service';
import { ToastNotificationService } from 'src/app/services/notification/toast-notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-a-page-to-a-topic',
  templateUrl: './add-a-page-to-a-topic.component.html',
  styleUrls: ['./add-a-page-to-a-topic.component.css']
})
export class AddAPageToATopicComponent implements OnInit {

  editorForm: FormGroup;
  editorForm2: FormGroup;
  public options = false;
  public general = true;
  public activities = true;
  public quizzes = true;
  public page = true
  public hasActivity = false;
  public hasQuiz = false;
  public textInPage = '';
  public quizSelected = '';
  public listOfActivities = [];
  blured = false
  focused = false

  public sections = [];
  public grades = [];
  public grades2 = [];
  public subjects = [];
  public subjects2 = [];
  public blocks = [];
  public blocks2 = [];
  public sequences = [];
  public sequences2 = [];
  public pages = [];
  public pages2 = [];
  public ambits = [];
  private ListadoPsl = [];
  public ListadoPsl2 = [];
  public axes = [];
  private topics = [];
  public topics2 = [];
  public isAmbit = undefined;
  public seccionSeleccionadaInPage = '';
  public gradoSeleccionadoInPage = '';
  public materiaSeleccionadaInPage = '';
  public bloqueSeleccionadaInPage = '';
  public secuenciaSeleccionadaInPage = '';
  public paginaSeleccionada = '';
  public ambitoSeleccionado = '';
  public pslSeleccionada2 = '';
  public ejeSeleccionado = '';
  public temaSeleccionado2 = '';
  private token = '';
  public infoPage = [];
  private imagenesSeleccionadas = [];
  private imagenesSeleccionadas2 = [];
  private videosSeleccionados = [];
  private audiosSeleccionados = [];
  private galeriasSeleccionadas = [];
  private wikisSeleccionados = [];
  private contenidoPagina;
  private contenidoPagina2;
  public aeDeUnTema = '';
  public aeDeUnPsl = '';
  public editandoPagina = false;
  public titleSequence = '';
  public idInteractivoSequence = '';
  opciones: NgbModalOptions;
  resultado: string;

  constructor(private modal: NgbModal, private notify: ToastNotificationService, private api: RestfulApiService, private auth: AuthServiceService, private router: Router) {
    this.editorForm = new FormGroup({
      'editor': new FormControl('')
    });
    this.editorForm2 = new FormGroup({
      'editor': new FormControl('')
    });
    this.opciones = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    if (sessionStorage.getItem('secciones') !== null) {
      this.sections = JSON.parse(sessionStorage.getItem('secciones'));
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('grados') !== null) {
      this.grades = JSON.parse(sessionStorage.getItem('grados'));
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('materias') !== null) {
      this.subjects = JSON.parse(sessionStorage.getItem('materias'));
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('bloques') !== null) {
      this.blocks = JSON.parse(sessionStorage.getItem('bloques'));
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('secuencias') !== null) {
      this.sequences = JSON.parse(sessionStorage.getItem('secuencias'));
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    if (sessionStorage.getItem('paginas') !== null) {
      this.pages = JSON.parse(sessionStorage.getItem('paginas'));
    } else {
      this.router.navigate(['', 'books', 'pages']);
    }
    this.token = this.auth.obtenertoken();
    const helper = new JwtHelperService();
    const tokenEspirado = helper.isTokenExpired(this.token);
    const info = helper.decodeToken(this.token);
    if (tokenEspirado) {
      this.router.navigate(['', 'auth']);
    } else {
      if (info.permisos[0].agregar_paginas === true) {
        let listado7;
        this.api.obtenerListadoAmbitos(this.token).subscribe(respuesta => {
          listado7 = JSON.parse(JSON.stringify(respuesta)).ambitos;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Ambitos");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Ambitos");
          }
        }, () => {
          this.ambits = listado7;
        });
        let listado8;
        this.api.obtenerListadoPSL(this.token).subscribe(respuesta => {
          listado8 = JSON.parse(JSON.stringify(respuesta)).psl;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "PSL");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "PSL");
          }
        }, () => {
          this.ListadoPsl = listado8;
          // console.log("PSL: ", this.ListadoPsl);
          // console.log("this.infoPage[0] 3:", this.infoPage[0]);
          if (this.infoPage[0] !== undefined) {
            this.ListadoPsl2 = this.filtrarPslPorMateria(this.materiaSeleccionadaInPage);
            this.ListadoPsl2.sort(function (a, b) {
              if (a.aprendizaje < b.aprendizaje) { return -1; }
            });
            this.pslSeleccionada2 = this.infoPage[0].psl;
            //console.log("PSL: ", this.pslSeleccionada2);
          }
        });
        let listado9;
        this.api.obtenerListadoEjes(this.token).subscribe(respuesta => {
          listado9 = JSON.parse(JSON.stringify(respuesta)).ejes;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Ejes");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Ejes");
          }
        }, () => {
          this.axes = listado9;
          // this.axes.sort(function(a, b){
          //   if(a.titulo < b.titulo) { return -1; }
          // });
        });
        let listado10;
        this.api.obtenerListadoTemas(this.token).subscribe(respuesta => {
          listado10 = JSON.parse(JSON.stringify(respuesta)).temas;
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            //console.log('Client-side error occured.');
          } else {
            //console.log('Server-side error occured.');
          }
          if (err.status === 400) {
            this.notify.showError("Error 400", "Temas");
          }
          if (err.status === 401 || err.status === 404) {
            this.auth.eliminarToken();
            this.router.navigate(['', 'auth']);
          }
          if (err.status === 500) {
            this.notify.showError("Error 500", "Temas");
          }
        }, () => {
          this.topics = listado10;
          this.topics.sort(function (a, b) {
            if (a.titulo < b.titulo) { return -1; }
          });
          // console.log("TEMAS: ", this.topics2);
          // console.log("this.infoPage[0] 3:", this.infoPage[0]);
          if (this.infoPage[0] !== undefined) {
            this.topics2 = this.filtrarTemasPorMateria(this.materiaSeleccionadaInPage);
            this.topics2.sort(function (a, b) {
              if (a.aprendizaje < b.aprendizaje) { return -1; }
            });
            this.temaSeleccionado2 = this.infoPage[0].tema;
            // console.log("TEMA: ", this.temaSeleccionado2);
          }
        });
      } else {
        this.router.navigate(['', 'auth']);
      }
    }
    // console.log("this.infoPage[0]: ", this.infoPage[0]);
    if (localStorage.getItem('detallePagina3') !== null) {
      this.editandoPagina = true;
      this.infoPage[0] = JSON.parse(localStorage.getItem('detallePagina3'));
      // console.log("this.infoPage[0] 2: ", this.infoPage[0]);
      localStorage.removeItem('detallePagina3');
      this.paginaSeleccionada = this.infoPage[0].paginaSeleccionada;
      this.seccionSeleccionadaInPage = this.infoPage[0].seccion;
      this.seleccionarNivelAcademico();
      this.gradoSeleccionadoInPage = this.infoPage[0].grado;
      this.seleccionarGrado();
      this.materiaSeleccionadaInPage = this.infoPage[0].materia;
      this.seleccionarMateria();
      this.bloqueSeleccionadaInPage = this.infoPage[0].bloque;
      this.seleccionarBloque();
      this.secuenciaSeleccionadaInPage = this.infoPage[0].secuencia;
      this.seleccionarSecuencia();
      this.listOfActivities = this.infoPage[0].actividades;
      this.ambitoSeleccionado = this.infoPage[0].ambito;
      this.editorForm.get('editor').setValue(this.infoPage[0].contenido);
      this.editorForm2.get('editor').setValue(this.infoPage[0].contenido2);
      this.ejeSeleccionado = this.infoPage[0].eje;
      this.galeriasSeleccionadas = this.infoPage[0].galerias;
      this.imagenesSeleccionadas = this.infoPage[0].imagenes;
      this.imagenesSeleccionadas2 = this.infoPage[0].imagenes2;
      this.aeDeUnPsl = this.infoPage[0].aprendizajePsl;
      this.aeDeUnTema = this.infoPage[0].aprendizajeTema;
      this.hasActivity = this.infoPage[0].tieneActividades;
      this.hasQuiz = this.infoPage[0].tieneQuiz;
      this.videosSeleccionados = this.infoPage[0].videos;
      this.audiosSeleccionados = this.infoPage[0].audios;
      this.wikisSeleccionados = this.infoPage[0].wikis;
    }
  }

  open(content) {
    this.modal.open(content, this.opciones).result.then((result) => {
      this.resultado = `Closed with: ${result}`;
    }, (reason) => {
      this.resultado = `Dismissed ${this.getReasons(reason)}`;
      // console.log(`Dismissed: ${reason}`);
    });
  }

  private getReasons(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'CLOSE';
    } else {
      return `${reason}`;
    }
  }

  listenNameOfSequence(name) {
    name.target.value = name.target.value.toUpperCase();
    this.titleSequence = name.target.value;
  }

  listenInteraciveOfSequence(name) {
    name.target.value = name.target.value.toUpperCase();
    this.idInteractivoSequence = name.target.value;
  }

  agregarSecuencia() {
    const infoMateria = this.filtrarMateriasPorId(this.materiaSeleccionadaInPage)[0];
    let idRespuesta;
    this.api.agregarUnaSecuencia(this.token, infoMateria.seccion, infoMateria.grado, this.materiaSeleccionadaInPage, this.bloqueSeleccionadaInPage, this.titleSequence, this.idInteractivoSequence).subscribe(respuesta => {
      idRespuesta = JSON.parse(JSON.stringify(respuesta)).id;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError('Error 400', 'Secuencias');
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 403) {
        this.notify.showWarning('El nombre ya existe en otra secuencia de la misma sesión. Prueba con otro diferente', 'Secuencias');
      }
      if (err.status === 500) {
        this.notify.showError('Error 500', 'Secuencias');
      }
    }, () => {
      this.sequences.push({
        id: idRespuesta,
        titulo: this.titleSequence,
        interactivo: this.idInteractivoSequence,
        materia: this.materiaSeleccionadaInPage,
        nombreMateria: this.filtrarMateriasPorId(this.materiaSeleccionadaInPage)[0].titulo,
        bloque: this.bloqueSeleccionadaInPage,
        nombreBloque: this.filtrarBloquesPorId(this.bloqueSeleccionadaInPage)[0].titulo,
        utilizado: 0
      });
      this.sequences2.push({
        id: idRespuesta,
        titulo: this.titleSequence,
        interactivo: this.idInteractivoSequence,
        materia: this.materiaSeleccionadaInPage,
        nombreMateria: this.filtrarMateriasPorId(this.materiaSeleccionadaInPage)[0].titulo,
        bloque: this.bloqueSeleccionadaInPage,
        nombreBloque: this.filtrarBloquesPorId(this.bloqueSeleccionadaInPage)[0].titulo,
        utilizado: 0
      });
      this.sequences2.sort(function (a, b) {
        if (a.titulo < b.titulo) { return -1; }
      });
      this.titleSequence = '';
      this.idInteractivoSequence = '';
      this.notify.showInfo('Se agregó la secuencia correctamente', 'Secuencias');
    });
  }

  seleccionarNivelAcademico() {
    if (this.seccionSeleccionadaInPage !== '') {
      this.grades2 = this.filtrarGradosPorSeccion(this.seccionSeleccionadaInPage);
    } else {
      this.grades2 = [];
    }
  }

  seleccionarGrado() {
    if (this.gradoSeleccionadoInPage !== '') {
      this.subjects2 = this.filtrarMateriasPorGrado(this.gradoSeleccionadoInPage);
    } else {
      this.subjects2 = [];
    }
  }

  seleccionarMateria() {
    if (this.materiaSeleccionadaInPage !== '') {
      this.blocks2 = this.filtrarBloquesPorMateria(this.materiaSeleccionadaInPage);
      this.isAmbit = this.filtrarMateriasPorId(this.materiaSeleccionadaInPage)[0].tiene_ambito;
      if (this.isAmbit) {
        this.ListadoPsl2 = this.filtrarPslPorMateria(this.materiaSeleccionadaInPage);
        this.ListadoPsl2.sort(function (a, b) {
          if (a.aprendizaje < b.aprendizaje) { return -1; }
        });
      } else {
        this.topics2 = this.filtrarTemasPorMateria(this.materiaSeleccionadaInPage);
        this.topics2.sort(function (a, b) {
          if (a.aprendizaje < b.aprendizaje) { return -1; }
        });
      }
    } else {
      this.blocks2 = [];
      this.ListadoPsl2 = [];
    }
  }

  seleccionarBloque() {
    if (this.bloqueSeleccionadaInPage !== '') {
      this.sequences2 = this.filtrarSecuenciasPorBloque(this.bloqueSeleccionadaInPage);
      this.sequences2.sort(function (a, b) {
        if (a.titulo < b.titulo) { return -1; }
      });
    } else {
      this.sequences2 = [];
    }
  }

  seleccionarSecuencia() {
    if (this.secuenciaSeleccionadaInPage !== '') {
      this.pages2 = this.filtrarPaginasPorSecuencia(this.secuenciaSeleccionadaInPage);
      if (this.pages2.length > 0) {
        this.paginaSeleccionada = this.pages2[this.pages2.length - 1].id;
      }
    } else {
      this.pages2 = [];
    }
  }

  filtrarBloquesPorId(key: string) {
    return this.blocks.filter(object => {
      return object.id === key;
    });
  }

  filtrarGradosPorSeccion(key: string) {
    return this.grades.filter(object => {
      return object.seccion === key;
    });
  }

  filtrarMateriasPorGrado(key: string) {
    return this.subjects.filter(object => {
      return object.grado === key;
    });
  }
  filtrarMateriasPorId(key: string) {
    return this.subjects.filter(object => {
      return object.id === key;
    });
  }

  filtrarBloquesPorMateria(key: string) {
    return this.blocks.filter(object => {
      return object.materia === key;
    });
  }

  filtrarSecuenciasPorBloque(key: string) {
    return this.sequences.filter(object => {
      return object.bloque === key;
    });
  }

  filtrarSecuenciasPorId(key: string) {
    return this.sequences.filter(object => {
      return object.id === key;
    });
  }

  filtrarPaginasPorSecuencia(key: string) {
    return this.pages.filter(object => {
      return object.secuencia === key;
    });
  }

  filtrarPaginasPorId(key: string) {
    return this.pages.filter(object => {
      return object.id === key;
    });
  }

  filtrarPslPorId(key: string) {
    return this.ListadoPsl.filter(object => {
      return object.id === key;
    });
  }

  filtrarPslPorMateria(key: string) {
    return this.ListadoPsl.filter(object => {
      return object.materia === key;
    });
  }

  filtrarTemasPorId(key: string) {
    return this.topics.filter(object => {
      return object.id === key;
    });
  }

  filtrarTemasPorMateria(key: string) {
    return this.topics.filter(object => {
      return object.materia === key;
    });
  }

  showOptions(boolean) {
    this.options = boolean;
  }

  setVisibility(variable, boolean) {
    if (variable === 'activity') {
      this.hasActivity = boolean;
    }
    if (variable === 'quiz') {
      this.hasQuiz = boolean;
    }
  }

  setImageTag(esParaPagina) {
    // const id = Math.floor((Math.random() * 1000000) + 10000);
    // this.imagenesSeleccionadas.push({id: id});
    // const valor = this.editorForm.get('editor').value;
    // this.editorForm.get('editor').setValue(`${valor} #imagenid='${id}'#`);
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;
    this.infoPage.push({
      paginaSeleccionada: this.paginaSeleccionada,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada2,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado2,
      aprendizajePsl: this.aeDeUnPsl,
      aprendizajeTema: this.aeDeUnTema,
      tieneActividades: this.hasActivity,
      tieneQuiz: this.hasQuiz,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage[this.infoPage.length - 1]));

    if (esParaPagina) {
      this.router.navigate(['', 'multimedia', 'images'], { queryParams: { agregando: 'true' } });
    } else {
      this.router.navigate(['', 'multimedia', 'images'], { queryParams: { agregandoguia: 'true' } });
    }
  }

  setGalleryTag() {
    // const id = Math.floor((Math.random() * 1000000) + 10000);
    // this.galeriasSeleccionadas.push({id: id});
    // const valor = this.editorForm.get('editor').value;
    // this.editorForm.get('editor').setValue(`${valor} #galeriaid='${id}'#`);
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;
    this.infoPage.push({
      paginaSeleccionada: this.paginaSeleccionada,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada2,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado2,
      aprendizajePsl: this.aeDeUnPsl,
      aprendizajeTema: this.aeDeUnTema,
      tieneActividades: this.hasActivity,
      tieneQuiz: this.hasQuiz,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage[this.infoPage.length - 1]));
    this.router.navigate(['', 'multimedia', 'galleries'], { queryParams: { agregando: 'true' } });
  }

  setVideoTag() {
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;
    this.infoPage.push({
      paginaSeleccionada: this.paginaSeleccionada,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada2,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado2,
      aprendizajePsl: this.aeDeUnPsl,
      aprendizajeTema: this.aeDeUnTema,
      tieneActividades: this.hasActivity,
      tieneQuiz: this.hasQuiz,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage[this.infoPage.length - 1]));
    this.router.navigate(['', 'multimedia', 'videos'], { queryParams: { agregando: 'true' } });
  }

  setAudioTag() {
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;
    this.infoPage.push({
      paginaSeleccionada: this.paginaSeleccionada,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada2,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado2,
      aprendizajePsl: this.aeDeUnPsl,
      aprendizajeTema: this.aeDeUnTema,
      tieneActividades: this.hasActivity,
      tieneQuiz: this.hasQuiz,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage[this.infoPage.length - 1]));
    this.router.navigate(['', 'multimedia', 'audios'], { queryParams: { agregando: 'true' } });
  }

  setWikiTag() {
    localStorage.removeItem('detallePagina2');
    const contenidoEditor = this.editorForm.get('editor').value;
    const contenidoEditor2 = this.editorForm2.get('editor').value;
    this.infoPage.push({
      paginaSeleccionada: this.paginaSeleccionada,
      secuencia: this.secuenciaSeleccionadaInPage,
      grado: this.gradoSeleccionadoInPage,
      actividades: this.listOfActivities,
      ambito: this.ambitoSeleccionado,
      bloque: this.bloqueSeleccionadaInPage,
      contenido: contenidoEditor,
      contenido2: contenidoEditor2,
      eje: this.ejeSeleccionado,
      galerias: this.galeriasSeleccionadas,
      imagenes: this.imagenesSeleccionadas,
      imagenes2: this.imagenesSeleccionadas2,
      materia: this.materiaSeleccionadaInPage,
      psl: this.pslSeleccionada2,
      seccion: this.seccionSeleccionadaInPage,
      tema: this.temaSeleccionado2,
      aprendizajePsl: this.aeDeUnPsl,
      aprendizajeTema: this.aeDeUnTema,
      tieneActividades: this.hasActivity,
      tieneQuiz: this.hasQuiz,
      videos: this.videosSeleccionados,
      audios: this.audiosSeleccionados,
      wikis: this.wikisSeleccionados
    });
    localStorage.setItem('detallePagina2', JSON.stringify(this.infoPage[this.infoPage.length - 1]));
    this.router.navigate(['', 'general', 'wikis'], { queryParams: { agregando: 'true' } });
  }

  listenTitleOfActivity(name, position) {
    const title = name.target.value;
    this.listOfActivities[position].title = title;
  }

  savePage() {
    const valor = this.editorForm.get('editor').value;
    const valor2 = this.editorForm2.get('editor').value;
    // let terminoDepurarActividades = false;
    // let positionActividad = 0;
    const actividadesLocal = this.depurarActividades(this.listOfActivities);
    this.listOfActivities = actividadesLocal;
    if (this.listOfActivities.length === 0) {
      this.hasActivity = false;
    }
    if (this.hasActivity === false) {
      this.listOfActivities = [];
    }
    if (this.quizSelected === '') {
      this.hasQuiz = false;
    }
    //solicitar todos los campos llenos
    if (this.seccionSeleccionadaInPage !== '' && this.gradoSeleccionadoInPage !== '' && this.materiaSeleccionadaInPage !== '' &&
      this.bloqueSeleccionadaInPage !== '' && this.secuenciaSeleccionadaInPage !== '' && valor !== '') {
      if (this.pages2.length > 0) {
        // Solicitar que seleccione una página
        // if(this.paginaSeleccionada !== ''){
        //   this.revisarSiTieneAmbitos('Borrador', valor);
        // } else {
        //   this.notify.showWarning('Seleccione una página', 'Páginas');
        // }
        this.revisarSiTieneAmbitos('Borrador', valor, valor2);
      } else {
        // Es primer página
        this.revisarSiTieneAmbitos('Borrador', valor, valor2);
      }
    } else {
      if (valor === '') {
        this.notify.showWarning('Ingrese contenido a su página', 'Páginas');
      }
      // if(valor2 === ''){
      //   this.notify.showWarning('Ingrese contenido a su guía didáctica', 'Páginas');
      // }
      if (this.secuenciaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una secuencia', 'Páginas');
      }
      if (this.bloqueSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un bloque', 'Páginas');
      }
      if (this.materiaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una materia', 'Páginas');
      }
      if (this.gradoSeleccionadoInPage === '') {
        this.notify.showWarning('Seleccione un grado', 'Páginas');
      }
      if (this.seccionSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un nivel académico', 'Páginas');
      }
    }
  }

  revisarSiTieneAmbitos(estadoPagina, contenido, contenido2) {
    if (this.isAmbit) {
      if (this.ambitoSeleccionado !== '' && this.pslSeleccionada2 !== '') {
        this.hacerPeticion(estadoPagina, contenido, contenido2);
      } else {
        if (this.pslSeleccionada2 === '') {
          this.notify.showWarning('Seleccione una PSL', 'Páginas');
        }
        if (this.ambitoSeleccionado === '') {
          this.notify.showWarning('Seleccione un ámbito', 'Páginas');
        }
      }
    } else {
      if (this.ejeSeleccionado !== '' && this.temaSeleccionado2 !== '') {
        this.hacerPeticion(estadoPagina, contenido, contenido2);
      } else {
        if (this.temaSeleccionado2 === '') {
          this.notify.showWarning('Seleccione una tema', 'Páginas');
        }
        if (this.ejeSeleccionado === '') {
          this.notify.showWarning('Seleccione un eje', 'Páginas');
        }
      }
    }
  }

  hacerPeticion(estadoPagina, contenido, contenido2) {
    let estado;
    const wikisDepurados = this.depurarArreglo(this.wikisSeleccionados, 'Wiki');
    const wikisVerificados = this.verificarArreglo(wikisDepurados, contenido, 'Wiki');
    const imagenesDepuradas = this.depurarArreglo(this.imagenesSeleccionadas, 'Img');
    const imagenesVerificadas = this.verificarArreglo(imagenesDepuradas, contenido, 'Img');
    const imagenesDepuradas2 = this.depurarArreglo(this.imagenesSeleccionadas2, 'Img');
    const imagenesVerificadas2 = this.verificarArreglo(imagenesDepuradas2, contenido2, 'Img');
    const videosDepurados = this.depurarArreglo(this.videosSeleccionados, 'Video');
    const videoVerificados = this.verificarArreglo(videosDepurados, contenido, 'Video');
    const galeriasDepurados = this.depurarArreglo(this.galeriasSeleccionadas, 'Galeria');
    const galeriasVerificadas = this.verificarArreglo(galeriasDepurados, contenido, 'Galeria');
    const audiosDepurados = this.depurarArreglo(this.audiosSeleccionados, 'Audio');
    const audiosVerificados = this.verificarArreglo(audiosDepurados, contenido, 'Audio');
    // falta mandar el contenido2 de la guia didactica en la petición
    this.api.agregarUnaPagina(this.token, estadoPagina, this.seccionSeleccionadaInPage, this.gradoSeleccionadoInPage, this.materiaSeleccionadaInPage, this.bloqueSeleccionadaInPage, this.secuenciaSeleccionadaInPage, this.paginaSeleccionada, this.ambitoSeleccionado, this.pslSeleccionada2, this.ejeSeleccionado, this.temaSeleccionado2, this.hasActivity, this.listOfActivities, this.hasQuiz, this.quizSelected, contenido, imagenesVerificadas, videoVerificados, audiosVerificados, galeriasVerificadas, wikisVerificados, contenido2, imagenesVerificadas2).subscribe(respuesta => {
      estado = JSON.parse(JSON.stringify(respuesta)).estado;
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        //console.log('Client-side error occured.');
      } else {
        //console.log('Server-side error occured.');
      }
      if (err.status === 400) {
        this.notify.showError("Error 400", "Página");
      }
      if (err.status === 401 || err.status === 404) {
        this.auth.eliminarToken();
        this.router.navigate(['', 'auth']);
      }
      if (err.status === 500) {
        this.notify.showError("Error 500", "Página");
      }
    }, () => {
      if (estado === 201) {
        this.router.navigate(['', 'books', 'pages']);
      } else {
        this.notify.showWarning('Ocurrió un error al crear la página', 'Página');
      }
    });
  }

  sendToReview() {
    const valor = this.editorForm.get('editor').value;
    const valor2 = this.editorForm2.get('editor').value;
    // let terminoDepurarActividades = false;
    // let positionActividad = 0;
    const actividadesLocal = this.depurarActividades(this.listOfActivities);
    this.listOfActivities = actividadesLocal;
    if (this.listOfActivities.length === 0) {
      this.hasActivity = false;
    }
    if (this.hasActivity === false) {
      this.listOfActivities = [];
    }
    if (this.quizSelected === '') {
      this.hasQuiz = false;
    }
    //solicitar todos los campos llenos
    if (this.seccionSeleccionadaInPage !== '' && this.gradoSeleccionadoInPage !== '' && this.materiaSeleccionadaInPage !== '' &&
      this.bloqueSeleccionadaInPage !== '' && this.secuenciaSeleccionadaInPage !== '' && valor !== '') {
      if (this.pages2.length > 0) {
        // Solicitar que seleccione una página
        // if(this.paginaSeleccionada !== ''){
        //   this.revisarSiTieneAmbitos('Borrador', valor);
        // } else {
        //   this.notify.showWarning('Seleccione una página', 'Páginas');
        // }
        this.revisarSiTieneAmbitos('En revisión', valor, valor2);
      } else {
        // Es primer página
        this.revisarSiTieneAmbitos('En revisión', valor, valor2);
      }
    } else {
      if (valor === '') {
        this.notify.showWarning('Ingrese contenido a su página', 'Páginas');
      }
      // if(valor2 === ''){
      //   this.notify.showWarning('Ingrese contenido a su guía didáctica', 'Páginas');
      // }
      if (this.secuenciaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una secuencia', 'Páginas');
      }
      if (this.bloqueSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un bloque', 'Páginas');
      }
      if (this.materiaSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione una materia', 'Páginas');
      }
      if (this.gradoSeleccionadoInPage === '') {
        this.notify.showWarning('Seleccione un grado', 'Páginas');
      }
      if (this.seccionSeleccionadaInPage === '') {
        this.notify.showWarning('Seleccione un nivel académico', 'Páginas');
      }
    }
  }

  pushAnActivity() {
    this.listOfActivities.push({
      title: ''
    });
  }

  removeAnActivity(position) {
    this.listOfActivities.splice(position, 1);
  }

  selectQuiz() {
    const id = Math.floor((Math.random() * 1000000) + 10000);
    this.quizSelected = '' + id;
  }

  unselectQuiz() {
    this.quizSelected = '';
  }

  visualizarPagina(esUnaPagina: Boolean) {
    if (this.secuenciaSeleccionadaInPage !== '') {
      const contenidoEditor = this.editorForm.get('editor').value;
      const contenidoEditor2 = this.editorForm2.get('editor').value;
      this.contenidoPagina = contenidoEditor;
      this.contenidoPagina2 = contenidoEditor2;
      this.depurarWikis();
      this.depurarImagenes();
      this.depurarVideos();
      this.depurarGalerias();
      this.depurarAudios();
      const actividadesLocal = this.depurarActividades(this.listOfActivities);
      this.listOfActivities = actividadesLocal;
      // ALGORITMO PARA CAMBIAR IMÁGENES, VIDEOS Y GALERÍAS
      // 1. Buscar imágenes del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
      // 2. Buscar videos del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
      // 3. Buscar galerías del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
      // 4. Buscar wikis del listado en el contenido, si están, se reemplaza, si no, se elimina del listado
      // FINALMENTE GUARDAR ESE NUEVO CONTENIDO DE HTML EN LA VARIABLE CONTENIDO

      if (this.isAmbit === true) {
        if (this.pslSeleccionada2 !== "") {
          this.revisarPagina(esUnaPagina);
        } else {
          this.notify.showWarning('Selecciona una PSL', 'Páginas');
        }
      } else {
        if (this.temaSeleccionado2 !== "") {
          this.revisarPagina(esUnaPagina);
        } else {
          this.notify.showWarning('Selecciona un tema', 'Páginas');
        }
      }
    } else {
      this.notify.showWarning('Selecciona una secuencia', 'Páginas');
    }
  }

  seleccionarTema(tema) {
    this.aeDeUnTema = this.filtrarTemasPorId(tema)[0].titulo;
  }

  seleccionarPsl(psl) {
    this.aeDeUnPsl = this.filtrarPslPorId(psl)[0].titulo;
  }

  revisarPagina(esUnaPagina: Boolean) {
    if (this.infoPage.length === 0) {
      const infoSecuencia = this.filtrarSecuenciasPorId(this.secuenciaSeleccionadaInPage)[0];
      let pagina = 0;
      let aprendizaje = "";
      let titulo_del_tema_o_psl = "";
      if (this.paginaSeleccionada !== "") {
        pagina = this.filtrarPaginasPorId(this.paginaSeleccionada)[0].pagina;
      }
      if (this.isAmbit === true) {
        // Revisar aprendizaje esperado del tema
        const objeto = this.filtrarPslPorId(this.pslSeleccionada2)[0];
        titulo_del_tema_o_psl = objeto.titulo;
        aprendizaje = objeto.aprendizaje;
      } else {
        const objeto = this.filtrarTemasPorId(this.temaSeleccionado2)[0];
        titulo_del_tema_o_psl = objeto.titulo;
        aprendizaje = objeto.aprendizaje;
      }
      this.infoPage.push({
        secuencia: this.secuenciaSeleccionadaInPage,
        pagina: pagina + 1,
        esambito: this.isAmbit,
        titulo: titulo_del_tema_o_psl,
        aprendizaje: aprendizaje,
        espagina: esUnaPagina,
        contenido2: this.contenidoPagina,
        contenido3: this.contenidoPagina2,
        actividades: this.listOfActivities
      });
      // console.log("infoPage: ", this.infoPage);
      localStorage.removeItem('detallePagina4');
      localStorage.setItem('detallePagina4', JSON.stringify(this.infoPage[0]));
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/books/pages/view'])
      );
      window.open(url, '_blank');
    } else {
      this.infoPage[0].secuencia = this.secuenciaSeleccionadaInPage;
      const infoSecuencia = this.filtrarSecuenciasPorId(this.secuenciaSeleccionadaInPage)[0];
      this.infoPage[0].nombreSecuencia = infoSecuencia.titulo;
      if (this.paginaSeleccionada !== "") {
        this.infoPage[0].pagina = this.filtrarPaginasPorId(this.paginaSeleccionada)[0].pagina + 1;
      } else {
        this.infoPage[0].pagina = 0;
      }
      if (this.isAmbit === true) {
        // Revisar aprendizaje esperado del tema
        const objeto = this.filtrarPslPorId(this.pslSeleccionada2)[0];
        this.infoPage[0].aprendizaje = objeto.aprendizaje;
        this.infoPage[0].titulo = objeto.titulo;
        this.infoPage[0].ambito = true;
      } else {
        //this.infoPage[0].aprendizaje = this.filtrarTemasPorId(this.temaSeleccionado2)[0].aprendizaje;
        //this.infoPage[0].ambito = false;
        const objeto = this.filtrarTemasPorId(this.temaSeleccionado2)[0];
        this.infoPage[0].aprendizaje = objeto.aprendizaje;
        this.infoPage[0].titulo = objeto.titulo;
        this.infoPage[0].ambito = false;
      }
      this.infoPage[0].contenido2 = this.contenidoPagina;
      this.infoPage[0].contenido3 = this.contenidoPagina2;
      this.infoPage[0].actividades = this.listOfActivities;
      this.infoPage[0].espagina = esUnaPagina;
      // console.log("infoPage[0]: ", this.infoPage[0]);
      localStorage.removeItem('detallePagina4');
      localStorage.setItem('detallePagina4', JSON.stringify(this.infoPage[0]));
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/books/pages/view'])
      );
      window.open(url, '_blank');
    }
  }

  depurarWikis() {
    const wikisDepurados = this.depurarArreglo(this.wikisSeleccionados, 'Wiki');
    const wikisVerificados = this.verificarArreglo(wikisDepurados, this.contenidoPagina, 'Wiki');
    for (let m = 0; m < wikisVerificados.length; m++) {
      const nuevoContenido = this.contenidoPagina.replace(`#wikiid='W${wikisVerificados[m].id}'#`, `<button type="button" style="border: none;background-color: transparent;outline: none;color: #F09D51;text-decoration: none;font-weight: bold;cursor: pointer;" data-toggle="modal" data-target="#W${wikisVerificados[m].id}">${wikisVerificados[m].titulo}</button>`);
      this.contenidoPagina = nuevoContenido;
    }
  }

  depurarImagenes() {
    const imagenesDepuradas = this.depurarArreglo(this.imagenesSeleccionadas, 'Img');
    const imagenesDepuradas2 = this.depurarArreglo(this.imagenesSeleccionadas2, 'Img');
    const imagenesVerificadas = this.verificarArreglo(imagenesDepuradas, this.contenidoPagina, 'Img');
    const imagenesVerificadas2 = this.verificarArreglo(imagenesDepuradas2, this.contenidoPagina2, 'Img');
    for (let m = 0; m < imagenesVerificadas.length; m++) {
      //const nuevoContenido = this.contenidoPagina.replace(`#imagenid='I${listadoImagenes[m].id}'#`,`<br/><img src="${listadoImagenes[m].src}" class="img-fluid pl-5 pr-5"><p class="mt-1 mb-2 text-center">${listadoImagenes[m].descripcion}</p><br/>`);
      let texto = '';
      if (imagenesVerificadas[m].descripcion !== '') {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas[m].id}"><img src="${imagenesVerificadas[m].src}" class="img-fluid pl-5 pr-5"></a><p class="mt-1 mb-2 text-center">${imagenesVerificadas[m].descripcion}</p><br/>`;
      } else {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas[m].id}"><img src="${imagenesVerificadas[m].src}" class="img-fluid pl-5 pr-5"></a><br/>`;
      }
      const nuevoContenido = this.contenidoPagina.replace(`#imagenid='I${imagenesVerificadas[m].id}'#`, texto);
      this.contenidoPagina = nuevoContenido;
    }
    for (let m = 0; m < imagenesVerificadas2.length; m++) {
      //const nuevoContenido = this.contenidoPagina.replace(`#imagenid='I${listadoImagenes[m].id}'#`,`<br/><img src="${listadoImagenes[m].src}" class="img-fluid pl-5 pr-5"><p class="mt-1 mb-2 text-center">${listadoImagenes[m].descripcion}</p><br/>`);
      let texto = '';
      if (imagenesVerificadas2[m].descripcion !== '') {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas2[m].id}"><img src="${imagenesVerificadas2[m].src}" class="img-fluid pl-5 pr-5"></a><p class="mt-1 mb-2 text-center">${imagenesVerificadas2[m].descripcion}</p><br/>`;
      } else {
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#I${imagenesVerificadas2[m].id}"><img src="${imagenesVerificadas2[m].src}" class="img-fluid pl-5 pr-5"></a><br/>`;
      }
      const nuevoContenido = this.contenidoPagina2.replace(`#imagenid='I${imagenesVerificadas2[m].id}'#`, texto);
      this.contenidoPagina2 = nuevoContenido;
    }
  }

  depurarVideos() {
    const videosDepurados = this.depurarArreglo(this.videosSeleccionados, 'Video');
    const videoVerificados = this.verificarArreglo(videosDepurados, this.contenidoPagina, 'Video');
    for (let m = 0; m < videoVerificados.length; m++) {
      let texto = '';
      // if(videoVerificados[m].descripcion !== ''){
      //   texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video controls class="mt-2 pl-5 pr-5" width="100%" controlsList="nodownload"><source src="${videoVerificados[m].src}">Lo sentimos, su navegador no admite videos incrustados.</video></a><p class="mt-2 mb-2 text-center">${videoVerificados[m].descripcion}</p><br/>`;
      // } else {
      //   texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video controls class="mt-2 pl-5 pr-5" width="100%" controlsList="nodownload"><source src="${videoVerificados[m].src}">Lo sentimos, su navegador no admite videos incrustados.</video></a><br/>`;
      // }
      const nombreConExtension = `${videoVerificados[m].id}${videoVerificados[m].src.substring(videoVerificados[m].src.length - 4, videoVerificados[m].src.length)}`;
      const extensionFile = nombreConExtension.substring(nombreConExtension.length - 3, nombreConExtension.length);
      const urlBase = videoVerificados[m].src.split('/')[2];
      if (videoVerificados[m].descripcion !== '') {
        //        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/api/v1.0.0/ebe/video/stream?video=${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><p class="mt-2 mb-2 text-center">${videoVerificados[m].descripcion}</p><br/>`;
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/uploads/${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><p class="mt-2 mb-2 text-center">${videoVerificados[m].descripcion}</p><br/>`;

      } else {
        // texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/api/v1.0.0/ebe/video/stream?video=${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><br/>`;
        texto = `<br/><a style="cursor:pointer;" data-toggle="modal" data-target="#V${videoVerificados[m].id}"><video poster="assets/images/cover.png" loop controls preload="none" class="mt-2 pl-0 pr-0" width="100%" controlsList="nodownload"><source type="video/${extensionFile}" src="https://${urlBase}/uploads/${nombreConExtension}">Lo sentimos, su navegador no admite videos incrustados.</video></a><br/>`;

      }
      const nuevoContenido = this.contenidoPagina.replace(`#videoid='V${videoVerificados[m].id}'#`, texto);
      this.contenidoPagina = nuevoContenido;
    }
  }

  depurarAudios() {
    const audiosDepurados = this.depurarArreglo(this.audiosSeleccionados, 'Audio');
    const audiosVerificados = this.verificarArreglo(audiosDepurados, this.contenidoPagina, 'Audio');
    for (let m = 0; m < audiosVerificados.length; m++) {
      let texto = '';
      const nombreConExtension = `${audiosVerificados[m].id}${audiosVerificados[m].src.substring(audiosVerificados[m].src.length - 5, audiosVerificados[m].src.length)}`;
      const urlBase = audiosVerificados[m].src.split('/')[2];
      if (audiosVerificados[m].descripcion !== '') {
        texto = `<br/><audio controls class="mt-2 pl-0 pr-0" style="width: 100%;" controlsList="nodownload"><source src="https://${urlBase}/api/v1.0.0/ebe/audio/stream?audio=${nombreConExtension}">Lo sentimos, su navegador no admite audios incrustados.</audio><p class="mt-2 mb-2 text-center">${audiosVerificados[m].descripcion}</p><br/>`;
      } else {
        texto = `<br/><audio controls class="mt-2 pl-0 pr-0" style="width: 100%;" controlsList="nodownload"><source src="https://${urlBase}/api/v1.0.0/ebe/audio/stream?audio=${nombreConExtension}">Lo sentimos, su navegador no admite audios incrustados.</audio><br/>`;
      }
      const nuevoContenido = this.contenidoPagina.replace(`#audioid='A${audiosVerificados[m].id}'#`, texto);
      this.contenidoPagina = nuevoContenido;
    }
  }

  depurarGalerias() {
    const galeriasDepurados = this.depurarArreglo(this.galeriasSeleccionadas, 'Galeria');
    const galeriasVerificadas = this.verificarArreglo(galeriasDepurados, this.contenidoPagina, 'Galeria');
    // const listadoGalerias = this.galeriasSeleccionadas;
    for (let m = 0; m < galeriasVerificadas.length; m++) {
      let carousel = '';
      let contador = 0;
      for (let k = 0; k < galeriasVerificadas[m].imagenes.length; k++) {
        contador = contador + 1;
        if (k === 0) {
          carousel = carousel.concat(carousel, ' ', `<div class="carousel-item active"><img src="${galeriasVerificadas[m].imagenes[k].src}" class="d-block w-100"></div>`);
        } else if (k > 0) {
          let carousel2 = '';
          carousel = carousel2.concat(carousel, ' ', `<div class="carousel-item"><img src="${galeriasVerificadas[m].imagenes[k].src}" class="d-block w-100"></div>`);
        }
      }
      if (contador === galeriasVerificadas[m].imagenes.length) {
        let nuevoContenido = '';
        if (galeriasVerificadas[m].descripcion !== '') {
          nuevoContenido = this.contenidoPagina.replace(`#galeriaid='G${galeriasVerificadas[m].id}'#`, `<div id="G${galeriasVerificadas[m].id}" class="carousel slide" data-ride="carousel"><div class="carousel-inner">${carousel}</div><a class="carousel-control-prev" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span></a><a class="carousel-control-next" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span></a></div><p class="mt-2 text-center">${galeriasVerificadas[m].descripcion}</p>`);
        } else {
          nuevoContenido = this.contenidoPagina.replace(`#galeriaid='G${galeriasVerificadas[m].id}'#`, `<div id="G${galeriasVerificadas[m].id}" class="carousel slide" data-ride="carousel"><div class="carousel-inner">${carousel}</div><a class="carousel-control-prev" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span></a><a class="carousel-control-next" href="#G${galeriasVerificadas[m].id}" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span></a></div>`);
        }
        this.contenidoPagina = nuevoContenido;
      }
    }
  }

  depurarActividades(actividades) {
    let actividadesLocal = [];
    let contador = 0;
    for (let i = 0; i < actividades.length; i++) {
      contador = contador + 1;
      if (actividades[i].title !== '') {
        actividadesLocal.push({
          title: actividades[i].title
        });
      }
    }
    if (contador === actividades.length) {
      return actividadesLocal;
    }
  }

  depurarArreglo(arreglo, tipo) {
    let arregloLocal = [];
    if (tipo === 'Wiki') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        contador = contador + 1;
        const buscarId = this.filtrarArreglo(arregloLocal, arreglo[i].id);
        // console.log("BUSCAR ID WIKI: ", buscarId);
        if (buscarId.length === 0) {
          arregloLocal.push({
            id: arreglo[i].id,
            titulo: arreglo[i].titulo,
            descripcion: arreglo[i].descripcion,
            count: 0
          });
        }
      }
      if (contador === arreglo.length) {
        let contador2 = 0;
        for (let k = 0; k < arregloLocal.length; k++) {
          contador2 = contador2 + 1;
          const contadorId = this.filtrarArreglo(arreglo, arregloLocal[k].id);
          arregloLocal[k].count = contadorId.length;
        }
        if (contador2 === arregloLocal.length) {
          return arregloLocal;
        }
      }
    } else if (tipo === 'Img' || tipo === 'Video' || tipo === 'Audio') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        contador = contador + 1;
        const buscarId = this.filtrarArreglo(arregloLocal, arreglo[i].id);
        if (buscarId.length === 0) {
          arregloLocal.push({
            id: arreglo[i].id,
            src: arreglo[i].src,
            descripcion: arreglo[i].descripcion,
            count: 0
          });
        }
      }
      if (contador === arreglo.length) {
        // return arregloLocal;
        let contador2 = 0;
        for (let k = 0; k < arregloLocal.length; k++) {
          contador2 = contador2 + 1;
          const contadorId = this.filtrarArreglo(arreglo, arregloLocal[k].id);
          arregloLocal[k].count = contadorId.length;
        }
        if (contador2 === arregloLocal.length) {
          return arregloLocal;
        }
      }
    } else if (tipo === 'Galeria') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        contador = contador + 1;
        const buscarId = this.filtrarArreglo(arregloLocal, arreglo[i].id);
        if (buscarId.length === 0) {
          arregloLocal.push({
            id: arreglo[i].id,
            descripcion: arreglo[i].descripcion,
            imagenes: arreglo[i].imagenes,
            count: 0
          });
        }
      }
      if (contador === arreglo.length) {
        // return arregloLocal;
        let contador2 = 0;
        for (let k = 0; k < arregloLocal.length; k++) {
          contador2 = contador2 + 1;
          const contadorId = this.filtrarArreglo(arreglo, arregloLocal[k].id);
          arregloLocal[k].count = contadorId.length;
        }
        if (contador2 === arregloLocal.length) {
          return arregloLocal;
        }
      }
    }
  }

  filtrarArreglo(listado, key) {
    return listado.filter(object => {
      return object.id === key;
    });
  }

  verificarArreglo(listado, contenido, tipo) {
    let arregloDepurado = listado;
    let contador = 0;
    for (let i = 0; i < arregloDepurado.length; i++) {
      contador = contador + 1;
      let matchEnContenido = [];
      if (tipo === 'Wiki') {
        matchEnContenido = this.matchWikis(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Img') {
        matchEnContenido = this.matchImages(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Video') {
        matchEnContenido = this.matchVideos(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Audio') {
        matchEnContenido = this.matchAudios(arregloDepurado[i].id, contenido);
      } else if (tipo === 'Galeria') {
        matchEnContenido = this.matchGalleries(arregloDepurado[i].id, contenido);
      }
      if (arregloDepurado[i].count !== matchEnContenido.length) {
        arregloDepurado[i].count = matchEnContenido.length;
      }
    }
    if (contador === arregloDepurado.length) {
      // Rellenar el arreglo con la nueva cantidad
      return this.rellenarArreglo(arregloDepurado, tipo);
    }
  }

  rellenarArreglo(arreglo, tipo) {
    let arregloLocal = [];
    if (tipo === 'Wiki') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        let contador2 = 0;
        for (let k = 0; k < arreglo[i].count; k++) {
          contador2 = contador2 + 1;
          arregloLocal.push({
            id: arreglo[i].id,
            titulo: arreglo[i].titulo,
            descripcion: arreglo[i].descripcion
          });
        }
        if (contador2 === arreglo[i].count) {
          contador = contador + 1;
        }
      }
      if (contador === arreglo.length) {
        return arregloLocal;
      }
    } else if (tipo === 'Img' || tipo === 'Video' || tipo === 'Audio') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        let contador2 = 0;
        for (let k = 0; k < arreglo[i].count; k++) {
          contador2 = contador2 + 1;
          arregloLocal.push({
            id: arreglo[i].id,
            src: arreglo[i].src,
            descripcion: arreglo[i].descripcion
          });
        }
        if (contador2 === arreglo[i].count) {
          contador = contador + 1;
        }
      }
      if (contador === arreglo.length) {
        return arregloLocal;
      }
    } else if (tipo === 'Galeria') {
      let contador = 0;
      for (let i = 0; i < arreglo.length; i++) {
        let contador2 = 0;
        for (let k = 0; k < arreglo[i].count; k++) {
          contador2 = contador2 + 1;
          arregloLocal.push({
            id: arreglo[i].id,
            descripcion: arreglo[i].descripcion,
            imagenes: arreglo[i].imagenes
          });
        }
        if (contador2 === arreglo[i].count) {
          contador = contador + 1;
        }
      }
      if (contador === arreglo.length) {
        return arregloLocal;
      }
    }
  }

  matchWikis(wikiId, contenido) {
    const regex = new RegExp(`#wikiid='W${wikiId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchImages(imageId, contenido) {
    const regex = new RegExp(`#imagenid='I${imageId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchGalleries(galeriaId, contenido) {
    const regex = new RegExp(`#galeriaid='G${galeriaId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchVideos(videoId, contenido) {
    const regex = new RegExp(`#videoid='V${videoId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }

  matchAudios(videoId, contenido) {
    const regex = new RegExp(`#audioid='A${videoId}'#`, 'gm');
    let m;
    let matches = [];

    do {
      m = regex.exec(contenido);
      if (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        } else {
          matches.push({
            index: m.index,
            lastIndex: regex.lastIndex
          });
        }
      } else {
        return matches;
      }
    } while (m !== null);
  }


}
